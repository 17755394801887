import styled from 'styled-components'

export const Container = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 50%;
  }
`

export const TextBlock = styled.div`
  text-align: center;
`

export const ButtonBlock = styled.div`
  width: 100%;
  margin-top: 20px;
`
