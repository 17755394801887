import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Avatar, Form, notification } from 'antd'

import NewModal from '../NewModal'
import AddButton from '../AddButton'
import { RootState } from '../../store/rootReducers'
import isMailValid from '../../utils/isMailValid'
import phoneMask from '../../utils/phoneMask'
import { UpdateUserPayload } from '../../store/modules/user/updateUserInfo/types'
import { updateUserInfo } from '../../store/modules/user/updateUserInfo/actions'
import { ChangePwdPayload } from '../../store/modules/user/changePwd/types'
import { changePwd } from '../../store/modules/user/changePwd/actions'
import { updateUserAvatar } from '../../store/modules/user/updateUserAvatar/actions'

import {
  Container,
  PersonalDataContainer,
  PersonalFormContainer,
  AvatarContainer,
  AvatarDesc,
  PersonalFormBlock,
  InputBlock,
  InputLabel,
  PersonalDataInput,
  AddButtonBlock,
  ChangePwdContainer,
  ChangePwdInput,
  ButtonContainer,
  ErrorText,
  CameraIcon
} from './styles'

interface Props {
  visible: boolean
  onCancel: () => void
}

const EditProfileModal: React.FC<Props> = ({ visible, onCancel }) => {
  const dispatch = useDispatch()
  const { TabPane } = Tabs

  const { token } = useSelector((state: RootState) => state.signIn)
  const { userInfo } = useSelector((state: RootState) => state.getUserInfo)
  const { updateEventLoading } = useSelector(
    (state: RootState) => state.updateEvent
  )
  const { changePwdLoading } = useSelector(
    (state: RootState) => state.changePwd
  )

  const fileInput = useRef<HTMLInputElement>(null)

  const [name, setName] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [image, setImage] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [oldPwd, setOldPwd] = useState<string>('')
  const [newPwd, setNewPwd] = useState<string>('')
  const [rNewPwd, setRNewPwd] = useState<string>('')

  const isNotAllowedToSubmitProfileData: boolean =
    name === '' ||
    mail === '' ||
    phone === '' ||
    !isMailValid(mail) ||
    (name === userInfo?.name &&
      mail === userInfo.email &&
      imageUrl === userInfo.image &&
      `+55${phone.replace(/\D/g, '')}` === userInfo?.cellphone)

  const isNotAllowedToSubmitChangePwd: boolean =
    oldPwd === '' ||
    newPwd === '' ||
    rNewPwd === '' ||
    newPwd !== rNewPwd ||
    oldPwd.length < 8 ||
    newPwd.length < 8 ||
    rNewPwd.length < 8

  const handleCloseModalAndClearData = (): void => {
    onCancel()
    setName('')
    setMail('')
    setPhone('')
    setImageUrl('')
    setImage(null)
    setOldPwd('')
    setNewPwd('')
    setRNewPwd('')
  }

  const handleSubmitPersonalData = (): void => {
    if (userInfo && token && !updateEventLoading) {
      const formatedPhone = `+55${phone.replace(/\D/g, '')}`

      const payload: UpdateUserPayload = {
        name: name,
        cellphone: formatedPhone,
        email: mail
      }

      if (
        name !== userInfo.name ||
        formatedPhone !== userInfo.cellphone ||
        mail !== userInfo.email
      ) {
        dispatch(updateUserInfo(payload, token))
      }

      if (imageUrl !== userInfo.image && image) {
        const dataForm = new FormData()
        dataForm.append('file', image)

        dispatch(updateUserAvatar(dataForm, token))
      }

      handleCloseModalAndClearData()
    }
  }

  const handleSubmitChangePwd = (): void => {
    if (token && !changePwdLoading) {
      const payload: ChangePwdPayload = {
        password: oldPwd,
        newPassword: newPwd
      }

      dispatch(changePwd(payload, token))

      handleCloseModalAndClearData()
    }
  }

  const isPwdEquals = (): boolean => {
    return newPwd === rNewPwd
  }

  const fileFailValidation = (file: File) => {
    if (!file.type.includes('image') || file.size / 1024 / 1024 > 2) {
      return true
    }
    return false
  }

  const renderImageInput = () => fileInput.current?.click()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileChangedHandler = (event: any): void => {
    if (!event.target.files[0]) return

    if (fileFailValidation(event.target.files[0])) {
      notification.config({ duration: 3 })
      notification.warning({
        message:
          'O arquivo selecionado precisa ser uma imagem valida no formato Jpg ou Png de até 2MB'
      })
      return
    }

    setImage(event.target.files[0])
    setImageUrl(URL.createObjectURL(event.target.files[0]))
  }

  const personalDataForm = (): React.ReactNode => {
    return (
      <PersonalDataContainer>
        <PersonalFormContainer>
          <AvatarContainer onClick={renderImageInput}>
            <input
              onChange={fileChangedHandler}
              style={{ display: 'none' }}
              type="file"
              id="img"
              name="img"
              accept="image/*"
              ref={fileInput}
            />
            <Avatar size={94} src={imageUrl} />
            <CameraIcon />
            <AvatarDesc>Carregar imagem</AvatarDesc>
          </AvatarContainer>
          <PersonalFormBlock>
            <InputBlock>
              <InputLabel>Nome</InputLabel>
              <PersonalDataInput
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </InputBlock>
            <InputBlock>
              <InputLabel>E-mail</InputLabel>
              <PersonalDataInput
                value={mail}
                onChange={e => setMail(e.target.value)}
              />
            </InputBlock>
            <InputBlock>
              <InputLabel>Telefone</InputLabel>
              <PersonalDataInput
                value={phone}
                onChange={e => setPhone(phoneMask(e.target.value))}
              />
            </InputBlock>
          </PersonalFormBlock>
        </PersonalFormContainer>
        <AddButtonBlock style={{ alignSelf: 'flex-end' }}>
          <AddButton
            label="Salvar"
            onClickEvent={handleSubmitPersonalData}
            disabled={isNotAllowedToSubmitProfileData}
          />
        </AddButtonBlock>
      </PersonalDataContainer>
    )
  }

  const renderChangePwdForm = (): React.ReactNode => {
    return (
      <Form>
        <ChangePwdContainer>
          <InputBlock>
            <InputLabel>Senha</InputLabel>
            <Form.Item
              name="oldPwd"
              rules={[
                { required: true, message: 'Insira sua senha atual' },
                { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
              ]}
            >
              <ChangePwdInput
                value={oldPwd}
                onChange={e => setOldPwd(e.target.value)}
              />
            </Form.Item>
          </InputBlock>
          <InputBlock>
            <InputLabel>Nova Senha</InputLabel>
            <Form.Item
              name="newPwd"
              rules={[
                { required: true, message: 'Insira sua nova senha' },
                { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
              ]}
            >
              <ChangePwdInput
                value={newPwd}
                onChange={e => setNewPwd(e.target.value)}
              />
            </Form.Item>
          </InputBlock>
          <InputBlock>
            <InputLabel>Confirmar senha</InputLabel>
            <Form.Item
              name="rNewPwd"
              rules={[
                { required: true, message: 'Digite novamente sua nova senha' },
                { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
              ]}
            >
              <ChangePwdInput
                value={rNewPwd}
                onChange={e => setRNewPwd(e.target.value)}
              />
            </Form.Item>
          </InputBlock>
          <ButtonContainer>
            <AddButtonBlock>
              <AddButton
                label="Salvar"
                onClickEvent={handleSubmitChangePwd}
                disabled={isNotAllowedToSubmitChangePwd}
              />
            </AddButtonBlock>
            {!isPwdEquals() ? <ErrorText>Senhas diferentes</ErrorText> : null}
          </ButtonContainer>
        </ChangePwdContainer>
      </Form>
    )
  }

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
      setMail(userInfo.email)
      setPhone(
        userInfo.cellphone !== undefined
          ? phoneMask(userInfo.cellphone.slice(3))
          : ''
      )
      setImageUrl(userInfo.image)
    }
  }, [])

  return (
    <NewModal
      title="Editar perfil"
      visible={visible}
      onCancel={handleCloseModalAndClearData}
    >
      <Container>
        <Tabs defaultActiveKey="1" tabBarStyle={{ color: '#888888' }}>
          <TabPane tab="Dados pessoais" key="1">
            {personalDataForm()}
          </TabPane>
          <TabPane tab="Senha" key="2">
            {renderChangePwdForm()}
          </TabPane>
        </Tabs>
      </Container>
    </NewModal>
  )
}

export default EditProfileModal
