import React from 'react'

import { Container, LabelText } from './styles'

interface Props {
  label: string
  navigateTo: string
}

const NavigateButton: React.FC<Props> = ({ label, navigateTo }) => {
  const isSelected: boolean = location.pathname === navigateTo

  return (
    <Container
      to={navigateTo}
      style={isSelected ? { backgroundColor: '#2a569f' } : undefined}
    >
      <LabelText style={isSelected ? { color: '#fff' } : undefined}>
        {label}
      </LabelText>
    </Container>
  )
}

export default NavigateButton
