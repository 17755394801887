export const GET_EVENTS_REQUEST = '@events/GET_EVENTS_REQUEST'
export const GET_EVENTS_SUCCESS = '@events/GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = '@events/GET_EVENTS_FAILURE'
export const GET_EVENTS_ERASE = '@events/GET_EVENTS_ERASE'

export type EventType = {
  _id: string
  description: string
}

export type EventData = {
  year: string
  events: EventType[]
}

export type GetEventsState = {
  getEventsLoading: boolean | null
  getEventsEmpty: boolean | null
  getEventsFail: boolean | null
  eventsData: EventData[] | null
}

export type GetEventsRequestAction = {
  type: typeof GET_EVENTS_REQUEST
  token: string
}

export type GetEventsSuccessResponse = {
  data: EventData[]
}

export type GetEventsSuccessAction = {
  type: typeof GET_EVENTS_SUCCESS
  response: GetEventsSuccessResponse
}

export type GetEventsFailureAction = {
  type: typeof GET_EVENTS_FAILURE
}

export type GetEventsEraseAction = {
  type: typeof GET_EVENTS_ERASE
}

export type GetEventsActionTypes =
  | GetEventsRequestAction
  | GetEventsSuccessAction
  | GetEventsFailureAction
  | GetEventsEraseAction
