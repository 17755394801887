import produce from 'immer'

import {
  UpdateUserAvatarState,
  UpdateUserAvatarActionTypes,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_AVATAR_ERASE
} from './types'

const INITIAL_STATE: UpdateUserAvatarState = {
  updateUserAvatarLoading: null,
  updateUserAvatarFail: null
}

export default function updateUserAvatar(
  state = INITIAL_STATE,
  action: UpdateUserAvatarActionTypes
): UpdateUserAvatarState {
  switch (action.type) {
    case UPDATE_USER_AVATAR_REQUEST:
      return produce(state, draft => {
        draft.updateUserAvatarLoading = true
        draft.updateUserAvatarFail = INITIAL_STATE.updateUserAvatarFail
      })
    case UPDATE_USER_AVATAR_SUCCESS:
      return produce(state, draft => {
        draft.updateUserAvatarLoading = false
        draft.updateUserAvatarFail = false
      })
    case UPDATE_USER_AVATAR_FAILURE:
      return produce(state, draft => {
        draft.updateUserAvatarLoading = false
        draft.updateUserAvatarFail = true
      })
    case UPDATE_USER_AVATAR_ERASE:
      return produce(state, draft => {
        draft.updateUserAvatarLoading = INITIAL_STATE.updateUserAvatarLoading
        draft.updateUserAvatarFail = INITIAL_STATE.updateUserAvatarFail
      })
    default:
      return state
  }
}
