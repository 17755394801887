import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  height: auto;
  overflow: hidden;
  margin-right: 5px;
`

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-left: 1px solid #afafaf;
  padding-left: 5px;
  min-height: 90px;
  max-height: 180px;
  width: 100%;
  overflow-y: auto;
`

export const EventText = styled.span`
  color: #888888;
  font-size: 14px;
  padding-bottom: 5px;
`

export const YearContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

export const YearBlockImage = styled.img``

export const YearText = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  right: 110px;
  top: 5px;
`
