import produce from 'immer'

import {
  UpdateUserActionTypes,
  UpdateUserInfoState,
  UPDATE_USER_ERASE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from './types'

const INITIAL_STATE: UpdateUserInfoState = {
  updateUserLoading: null,
  updateUserFail: null
}

export default function updateUserInfo(
  state = INITIAL_STATE,
  action: UpdateUserActionTypes
): UpdateUserInfoState {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return produce(state, draft => {
        draft.updateUserLoading = true
        draft.updateUserFail = INITIAL_STATE.updateUserFail
      })
    case UPDATE_USER_SUCCESS:
      return produce(state, draft => {
        draft.updateUserLoading = false
        draft.updateUserFail = false
      })
    case UPDATE_USER_FAILURE:
      return produce(state, draft => {
        draft.updateUserLoading = false
        draft.updateUserFail = true
      })
    case UPDATE_USER_ERASE:
      return produce(state, draft => {
        draft.updateUserLoading = INITIAL_STATE.updateUserLoading
        draft.updateUserFail = INITIAL_STATE.updateUserFail
      })
    default:
      return state
  }
}
