import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { useHistory } from 'react-router'

import isMailValid from '../../../../utils/isMailValid'
import PrimaryButton from '../../../../components/PrimaryButton'
import { requestSms } from '../../../../store/modules/authentication/forgotPassword/requestSms/actions'
import { RootState } from '../../../../store/rootReducers'

import {
  Container,
  TextBlock,
  FormContainer,
  Error,
  ButtonContainer
} from './styles'

interface Props {
  setStep: (arg: number) => void
}

const RequestSms: React.FC<Props> = ({ setStep }) => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [mail, setMail] = useState<string>('')

  const { requestSmsLoading, requestSmsFail, requestSmsError } = useSelector(
    (state: RootState) => state.requestSms
  )

  const isNotAllowedToSubmit: boolean =
    mail === '' || !isMailValid(mail) || !!requestSmsLoading

  const handleSubmit = useCallback(() => {
    if (!isNotAllowedToSubmit && !requestSmsLoading) {
      dispatch(requestSms(mail))
    }
  }, [mail])

  useEffect(() => {
    if (requestSmsLoading === false && requestSmsFail === false) {
      setStep(2)
    }
  }, [requestSmsLoading, requestSmsFail])

  return (
    <Container>
      <Form>
        <TextBlock>
          <span>
            Insira o e-mail usado no seu cadastro para que possamos enviar um
            SMS para o reset da senha
          </span>
        </TextBlock>
        <FormContainer>
          <Form.Item
            name="mail"
            rules={[
              {
                type: 'email',
                message: 'Email inválido'
              }
            ]}
          >
            <Input
              value={mail}
              placeholder="Digite seu e-mail"
              onChange={e => setMail(e.target.value)}
              type="email"
            />
          </Form.Item>
          {requestSmsFail && !!requestSmsError && (
            <Error>
              <span>{requestSmsError}</span>
            </Error>
          )}
          <div>
            <PrimaryButton
              label="Enviar SMS"
              onClick={handleSubmit}
              disabled={isNotAllowedToSubmit}
            />
          </div>
          <ButtonContainer>
            <Button type="link" onClick={() => setStep(1)}>
              Esqueci meu e-mail
            </Button>
            <Button type="link" onClick={() => push('/authentication/signin')}>
              Voltar
            </Button>
          </ButtonContainer>
        </FormContainer>
      </Form>
    </Container>
  )
}

export default RequestSms
