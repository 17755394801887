export const GET_PHOTOS_REQUEST = '@gallery/GET_PHOTOS_REQUEST'
export const GET_PHOTOS_SUCCESS = '@gallery/GET_PHOTOS_SUCCESS'
export const GET_PHOTOS_FAILURE = '@gallery/GET_PHOTOS_FAILURE'

export const GET_PHOTOS_ERASE = '@gallery/GET_PHOTOS_ERASE'

export type PhotoType = {
  _id: string
  filePath: string
}

export type GetPhotosState = {
  getPhotosLoading: boolean | null
  getPhotosFail: boolean | null
  photos: PhotoType[] | null
}

type GetPhotosAction = {
  type: typeof GET_PHOTOS_REQUEST
  token: string
}

type GetPhotosSuccessAction = {
  type: typeof GET_PHOTOS_SUCCESS
  response: PhotoType[]
}

type GetPhotosFailureAction = {
  type: typeof GET_PHOTOS_FAILURE
}

type GetPhotosEraseAction = {
  type: typeof GET_PHOTOS_ERASE
}

export type GetPhotosActionTypes =
  | GetPhotosAction
  | GetPhotosSuccessAction
  | GetPhotosFailureAction
  | GetPhotosEraseAction
