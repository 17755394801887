import React from 'react'

import { SecondHeaderContainer, SecondHeaderText } from './styles'

interface Props {
  title: string
  content?: React.ReactNode
}

const SecondHeader: React.FC<Props> = ({ title, content }) => {
  return (
    <SecondHeaderContainer>
      <SecondHeaderText>{title}</SecondHeaderText>
      {content}
    </SecondHeaderContainer>
  )
}

export default SecondHeader
