import produce from 'immer'

import {
  GetUserInfoActionTypes,
  GetUserInfoState,
  GET_USER_INFO_ERASE,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS
} from './types'

const INITIAL_STATE: GetUserInfoState = {
  getUserInfoLoading: null,
  getUserInfoFail: null,
  userInfo: null
}

export default function getUserInfo(
  state = INITIAL_STATE,
  action: GetUserInfoActionTypes
): GetUserInfoState {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return produce(state, draft => {
        draft.getUserInfoLoading = true
        draft.getUserInfoFail = INITIAL_STATE.getUserInfoFail
        draft.userInfo = INITIAL_STATE.userInfo
      })
    case GET_USER_INFO_SUCCESS:
      return produce(state, draft => {
        draft.getUserInfoLoading = false
        draft.getUserInfoFail = false
        draft.userInfo = action.response
      })
    case GET_USER_INFO_FAILURE:
      return produce(state, draft => {
        draft.getUserInfoLoading = false
        draft.getUserInfoFail = true
      })
    case GET_USER_INFO_ERASE:
      return produce(state, draft => {
        draft.getUserInfoLoading = INITIAL_STATE.getUserInfoLoading
        draft.getUserInfoFail = INITIAL_STATE.getUserInfoFail
        draft.userInfo = INITIAL_STATE.userInfo
      })
    default:
      return state
  }
}
