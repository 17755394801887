import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { getPhotosFailure, getPhotosSuccess } from './actions'
import { GET_PHOTOS_REQUEST } from './types'

export function* getPhotos(requestPayload: AnyAction) {
  try {
    const { token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    const response: AxiosResponse = yield call(api.get, 'gallery', config)

    yield put(getPhotosSuccess(response.data))
  } catch {
    yield put(getPhotosFailure())
  }
}

export default all([takeLatest(GET_PHOTOS_REQUEST, getPhotos)])
