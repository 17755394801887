import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import AddButton from '../AddButton'
import { RootState } from '../../store/rootReducers'
import { PostEventPayload } from '../../store/modules/events/postEvent/types'
import { postEvent } from '../../store/modules/events/postEvent/actions'
import NewModal from '../NewModal'

import {
  Container,
  DatePickerInput,
  RightContainer,
  TextAreaInput
} from './styles'

interface Props {
  visible: boolean
  onCancel: () => void
}

const PostEventModal: React.FC<Props> = ({ visible, onCancel }) => {
  const dispatch = useDispatch()

  const [year, setYear] = useState<Moment | null>(null)
  const [description, setDescription] = useState<string>('')

  const { token } = useSelector((state: RootState) => state.signIn)
  const { postEventLoading } = useSelector(
    (state: RootState) => state.postEvent
  )

  const isNotAllowedToSubmit: boolean = year === null || description === ''

  const handlePostEventSubmit = (): void => {
    if (!isNotAllowedToSubmit && !postEventLoading && token) {
      const payload: PostEventPayload = {
        description,
        year: moment(year).year().toString()
      }

      dispatch(postEvent(payload, token))

      onCancel()
    }
  }

  return (
    <NewModal title="Adicionar evento" visible={visible} onCancel={onCancel}>
      <Container>
        <TextAreaInput
          placeholder="Descrição"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <RightContainer>
          <DatePickerInput
            placeholder="Ano"
            value={year}
            picker="year"
            onChange={value => {
              setYear(value)
            }}
          />
          <AddButton
            label="Adicionar"
            onClickEvent={handlePostEventSubmit}
            disabled={isNotAllowedToSubmit}
          />
        </RightContainer>
      </Container>
    </NewModal>
  )
}

export default PostEventModal
