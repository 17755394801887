import { call, put, all, takeLatest } from 'redux-saga/effects'

import api from '../../../../../services/api'
import { checkCodeSuccess, checkCodeFailure } from './actions'
import { CheckCodeAction, CHECK_CODE_REQUEST } from './types'

export function* checkCode({ token, code }: CheckCodeAction) {
  try {
    yield call(api.post, 'user/requestSms', {
      code,
      token: token
    })

    yield put(checkCodeSuccess())
  } catch (err) {
    const { data } = err.response

    yield put(checkCodeFailure(data))
  }
}

export default all([takeLatest(CHECK_CODE_REQUEST, checkCode)])
