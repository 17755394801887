import {
  DeletePhotoActionTypes,
  DELETE_PHOTO_ERASE,
  DELETE_PHOTO_FAILURE,
  DELETE_PHOTO_REQUEST,
  DELETE_PHOTO_SUCCESS
} from './types'

export function deletePhoto(
  _id: string,
  token: string
): DeletePhotoActionTypes {
  return {
    type: DELETE_PHOTO_REQUEST,
    _id,
    token
  }
}

export function deletePhotoSuccess(): DeletePhotoActionTypes {
  return {
    type: DELETE_PHOTO_SUCCESS
  }
}

export function deletePhotoFailure(): DeletePhotoActionTypes {
  return {
    type: DELETE_PHOTO_FAILURE
  }
}

export function deletePhotoErase(): DeletePhotoActionTypes {
  return {
    type: DELETE_PHOTO_ERASE
  }
}
