/* eslint-disable multiline-ternary */

import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import * as c from './styles'
import moment from 'moment'

type Props = {
  action: 'create' | 'edit' | 'delete'
  modelEntity: string
  createdAt: string
  executedBy: string
  modelEntityName: string
}
const Activity: React.FC<Props> = ({
  modelEntity,
  action,
  createdAt,
  executedBy,
  modelEntityName
}) => {
  const actions = {
    create: 'criou',
    edit: 'editou',
    delete: 'deletou'
  }
  return (
    <c.Container>
      <c.InnerContainer>
        <c.IntialsCircle>
          <FaUserAlt size={20} color="7f7f7f" />
        </c.IntialsCircle>

        <c.ActivityContent>
          <span>
            <strong style={{ marginRight: 5 }}>{executedBy}</strong>
          </span>
          {actions[action]}
          <span>
            <strong style={{ marginLeft: 5 }}>{modelEntityName}</strong>
          </span>
          <span style={{ marginLeft: 5 }}>em</span>
          <span style={{ marginLeft: 5 }}>
            {moment(createdAt.replace('T00:00:00.000Z', '')).format(
              'DD/MM/YYYY'
            )}
          </span>
        </c.ActivityContent>
      </c.InnerContainer>
    </c.Container>
  )
}

export default Activity
