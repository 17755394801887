import {
  DeleteEventActionTypes,
  DELETE_EVENT_ERASE,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS
} from './types'

export function deleteEvent(
  _id: string,
  token: string
): DeleteEventActionTypes {
  return {
    type: DELETE_EVENT_REQUEST,
    _id,
    token
  }
}

export function deleteEventSuccess(): DeleteEventActionTypes {
  return {
    type: DELETE_EVENT_SUCCESS
  }
}

export function deleteEventFailure(): DeleteEventActionTypes {
  return {
    type: DELETE_EVENT_FAILURE
  }
}

export function deleteEventErase(): DeleteEventActionTypes {
  return {
    type: DELETE_EVENT_ERASE
  }
}
