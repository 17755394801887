export const POST_EVENT_REQUEST = '@events/POST_EVENT_REQUEST'
export const POST_EVENT_SUCCESS = '@events/POST_EVENT_SUCCESS'
export const POST_EVENT_FAILURE = '@events/POST_EVENT_FAILURE'
export const POST_EVENT_ERASE = '@events/POST_EVENT_ERASE'

export type PostEventState = {
  postEventLoading: boolean | null
  postEventFail: boolean | null
}

export type PostEventPayload = {
  description: string
  year: string
}

export type PostEventSuccessResponse = {
  _id: string
  description: string
  year: string
  createdAt: string
  updatedAt: string
  __v: number
}

export type PostEventRequestAction = {
  type: typeof POST_EVENT_REQUEST
  payload: PostEventPayload
  token: string
}

export type PostEventSuccessAction = {
  type: typeof POST_EVENT_SUCCESS
  response: PostEventSuccessResponse
}

export type PostEventFailureAction = {
  type: typeof POST_EVENT_FAILURE
}

export type PostEventEraseAction = {
  type: typeof POST_EVENT_ERASE
}

export type PostEventActionTypes =
  | PostEventRequestAction
  | PostEventSuccessAction
  | PostEventFailureAction
  | PostEventEraseAction
