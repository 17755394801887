export const RESET_PASSWORD_REQUEST = '@forgotPassword/RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = '@forgotPassword/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = '@forgotPassword/RESET_PASSWORD_FAILURE'

export const RESET_PASSWORD_ERASE = '@forgotPassword/RESET_PASSWORD_ERASE'

export type ResetPasswordState = {
  resetPasswordLoading: boolean | null
  resetPasswordFail: boolean | null
  resetPasswordError: string | null
}

export type ResetPasswordAction = {
  type: typeof RESET_PASSWORD_REQUEST
  code: string
  password: string
}

type ResetPasswordSuccessAction = {
  type: typeof RESET_PASSWORD_SUCCESS
}

export type ResetPasswordFailureResponse = {
  message: string
}

type ResetPasswordFailureAction = {
  type: typeof RESET_PASSWORD_FAILURE
  response: ResetPasswordFailureResponse
}

type ResetPasswordEraseAction = {
  type: typeof RESET_PASSWORD_ERASE
}

export type ResetPasswordActionTypes =
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | ResetPasswordEraseAction
