import { Button, Input, notification, Popconfirm, Table } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import mime from 'mime-types'
import moment from 'moment'
import React, { useState } from 'react'
import { BiPencil } from 'react-icons/bi'
import { FaDownload, FaTrash } from 'react-icons/fa'
import { mutate } from 'swr'
import { FileI, Repository } from '../../pages/FileList/types'
import api from '../../services/api'
import { checkRole } from '../../utils/checkRole'
import NewModal from '../NewModal'

type Props = {
  data: Repository
  accordionHook: [number | null, (value: number | null) => void]
  index: number
}

const handleFileDelete = (id: string) => {
  api
    .post(
      'file/delete',
      { _id: id },
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(res => {
      if (res.status === 200) {
        mutate('repository/all').then(() => {
          notification.success({
            message: 'Arquivo deletado com sucesso',
            duration: 3
          })
        })
      }
    })
}

const handleRepositoryDelete = (id: string) => {
  api
    .post(
      'repository/delete',
      { _id: id },
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(res => {
      if (res.status === 200) {
        mutate('repository/all').then(() => {
          notification.success({
            message: 'Repositório deletado com sucesso!',
            duration: 3
          })
        })
      }
    })
}

const handleRepositoryEdit = (
  id: string | undefined,
  title: string | undefined
) => {
  api
    .post(
      'repository/edit',
      { _id: id, title: title },
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(res => {
      if (res.status === 200) {
        mutate('repository/all').then(() => {
          notification.success({
            message: 'Repositório editado com sucesso!',
            duration: 3
          })
        })
      }
    })
}

const columns = [
  {
    title: 'Data de Upload',
    dataIndex: 'createdAt',
    key: 'createdAy',
    render: (item: FileI) => (
      <span>{moment(item.createdAt).format('DD/MM/YY')}</span>
    )
  },
  {
    title: 'Tipo do arquivo',
    dataIndex: 'mimeType',
    key: 'mimeType',
    render: (item: string) => {
      return <span>{mime.extension(item).toString().toUpperCase()}</span>
    }
  },
  {
    title: 'Documento',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Download',
    key: 'address',
    render: (item: FileI) => {
      return (
        <FaDownload
          key={item._id}
          cursor="pointer"
          onClick={() => {
            window.open(item.filePath, '_blank')
          }}
        />
      )
    }
  },
  checkRole()
    ? {
        title: 'Ações',
        render: (item: FileI) => {
          return (
            <Popconfirm
              title="Você realmente deseja excluir esse arquivo ?"
              onConfirm={() => handleFileDelete(item._id)}
              cancelText="Cancelar"
              okText="Sim, excluir!"
            >
              <FaTrash key={item._id} cursor="pointer" />
            </Popconfirm>
          )
        }
      }
    : {}
]
const FileAccordion: React.FC<Props> = ({ data, accordionHook, index }) => {
  const [editModal, setEditModal] = useState<boolean>(false)
  const [selectedRepo, setSelectedRepo] = useState<Repository>()
  const [newRepoTitle, setNewRepoTitle] = useState<string>('')

  if (!data.files) {
    return <span>Carregando...</span>
  }
  return (
    <div
      key={data._id}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 10
      }}
    >
      <motion.div
        style={{
          backgroundColor: '#ffff',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
          padding: 10,
          borderRadius: 5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxWidth: '100%',
          width: 800,
          borderBottom: '1px solid var(--primary)',
          transition: 'all 2s',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        initial={false}
        onClick={() => {
          if (accordionHook[0] === index) {
            accordionHook[1](null)
          } else {
            accordionHook[1](index)
          }
        }}
      >
        <span style={{ fontSize: 20, fontWeight: 'bold' }} key={data._id}>
          {data.title}
        </span>
        {checkRole() ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <Popconfirm
              title="Você tem certeza que deseja excluir esse repositório? (Todos os itens serão perdidos)"
              onConfirm={() => handleRepositoryDelete(data._id)}
              cancelText="Cancelar"
              okText="Sim, excluir!"
            >
              <FaTrash cursor="pointer" style={{ marginRight: 10 }} />
            </Popconfirm>

            <BiPencil
              cursor="pointer"
              size={18}
              onClick={() => {
                setEditModal(true)
                setNewRepoTitle(data.title)
                setSelectedRepo(data)
              }}
            />
          </div>
        ) : null}
      </motion.div>
      <AnimatePresence>
        {accordionHook[0] === index && (
          <motion.div
            style={{
              display: 'flex',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              backgroundColor: '#fff',
              width: 800,
              padding: 10,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderRadius: 5
            }}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Table
              rowKey="_id"
              dataSource={data.files}
              columns={columns}
              locale={{
                emptyText: 'Não existem arquivos para esse repositório'
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <NewModal
        key="Editar repositório"
        visible={editModal}
        onCancel={() => setEditModal(!editModal)}
        title="Editar Repositório"
      >
        <div style={{ flexDirection: 'row', display: 'flex ' }}>
          <Input
            onChange={e => setNewRepoTitle(e.target.value)}
            bordered={false}
            style={{ backgroundColor: '#fafafa' }}
            defaultValue={selectedRepo?.title}
            value={newRepoTitle}
            placeholder="Novo título"
          />
          <Popconfirm
            title="Deseja realmente editar o respositório?"
            onConfirm={() => {
              handleRepositoryEdit(selectedRepo?._id, newRepoTitle)
              setEditModal(false)
            }}
            onCancel={() => setEditModal(!editModal)}
            cancelText="Cancelar"
            okText="Sim, editar!"
          >
            <Button
              style={{
                backgroundColor: '#57bb00',
                border: 'none',
                outline: 0,
                color: 'white',
                marginLeft: 5
              }}
            >
              Editar
            </Button>
          </Popconfirm>
        </div>
      </NewModal>
    </div>
  )
}

export default FileAccordion
