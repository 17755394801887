import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 130px;
  height: 80px;
  background-color: #fff;
  transition: 0.2s;
  padding: 10px;

  &:hover {
    background-color: #2a569f;

    span {
      color: #fff;
    }
  }

  @media (max-width: 1100px) {
    & {
      width: 100px;
    }

    span {
      font-size: 8px;
    }
  }
`

export const LabelText = styled.span`
  color: #038103;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-family: var(--font);
`
