import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
  margin-top: 10px;

  transition: all 0.2s ease;
  &:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  * + & {
    margin-top: 10px;
  }
`
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IntialsCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1.5px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

export const ActivityContent = styled.span`
  color: black;
  font-size: 13px;
  margin-left: 2%;
`
