import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: ${rotate} 1s linear infinite;
`
