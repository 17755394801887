import styled from 'styled-components'
import { Input } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 16px;
`

export const Error = styled.div`
  margin-bottom: 20px;
  text-align: center;

  span {
    color: #c53030;
    font-weight: 500;
  }
`

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CodeInput = styled(Input)`
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
`

export const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

export const SubmitButtonBlock = styled.div`
  width: 100%;
`

export const RequestSmsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RequestSmsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
`
