import React, { CSSProperties } from 'react'

import { ButtonBlock, ButtonLabelText } from './styles'

interface Props {
  onClick: () => void
  label: string
  style?: CSSProperties
  disabled?: boolean
}

const PrimaryButton: React.FC<Props> = ({
  onClick,
  label,
  style,
  disabled
}) => {
  return (
    <ButtonBlock
      onClick={!disabled ? onClick : undefined}
      style={style}
      disabled={disabled}
    >
      <ButtonLabelText>{label}</ButtonLabelText>
    </ButtonBlock>
  )
}

export default PrimaryButton
