import { FaLeaf } from 'react-icons/fa'
import styled, { keyframes } from 'styled-components'
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  margin-top: 20px;
`
export const ColumnHeader = styled.div`
  background-color: #f2f2f2;
  text-align: center;
  min-width: 95%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
`

export const ActionsBoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
`
export const ColumnTitle = styled.span`
  color: var(--green);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`
// card component
export const CardContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  /* min-height: 200px; */
  border-radius: 5px;

  * + & {
    margin-top: 10px;
  }
`

export const InnerContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const CardBody = styled.div`
  width: 100%;
  text-align: left;
`

export const CardContent = styled.p`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
`

export const CardPrazo = styled.span`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
`
export const LoadingIcon = styled(FaLeaf)`
  animation: ${rotate} 1s linear infinite;
`
