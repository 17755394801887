import {
  UpdateUserAvatarActionTypes,
  UPDATE_USER_AVATAR_ERASE,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS
} from './types'

export function updateUserAvatar(
  image: FormData,
  token: string
): UpdateUserAvatarActionTypes {
  return {
    type: UPDATE_USER_AVATAR_REQUEST,
    image,
    token
  }
}

export function updateUserAvatarSuccess(): UpdateUserAvatarActionTypes {
  return {
    type: UPDATE_USER_AVATAR_SUCCESS
  }
}

export function updateUserAvatarFailure(): UpdateUserAvatarActionTypes {
  return {
    type: UPDATE_USER_AVATAR_FAILURE
  }
}

export function updateUserAvatarErase(): UpdateUserAvatarActionTypes {
  return {
    type: UPDATE_USER_AVATAR_ERASE
  }
}
