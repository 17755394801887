import produce from 'immer'

import {
  SignInActionTypes,
  SignInState,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS
} from './types'

const INITIAL_STATE: SignInState = {
  signInLoading: null,
  token: null,
  signed: false,
  signInFail: null,
  signInError: null
}

export default function signIn(
  state = INITIAL_STATE,
  action: SignInActionTypes
): SignInState {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return produce(state, draft => {
        draft.signInLoading = true
        draft.signed = INITIAL_STATE.signed
        draft.token = INITIAL_STATE.token
        draft.signInFail = INITIAL_STATE.signInFail
        draft.signInError = INITIAL_STATE.signInError
      })
    case SIGN_IN_SUCCESS:
      return produce(state, draft => {
        draft.signInLoading = false
        draft.signInFail = false
        draft.signed = true
        draft.token = action.response.token
      })
    case SIGN_IN_FAILURE:
      return produce(state, draft => {
        draft.signInLoading = false
        draft.signInFail = true
        draft.signInError = action.response.message
      })
    default:
      return state
  }
}
