import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { RootState } from '../store/rootReducers'
import { checkRole } from '../utils/checkRole'

type Props = {
  exact: boolean
  path: string
  component: React.FunctionComponent<RouteComponentProps>
  private: boolean
}

const PrivateRoute: React.FC<Props> = ({ exact, path, component }) => {
  const { signed } = useSelector((state: RootState) => state.signIn)
  if (signed && checkRole()) {
    return <Route component={component} exact={exact} path={path} />
  }
  return <Redirect to="/authentication/signin" />
}
export default PrivateRoute
