import React, { useState } from 'react'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Contact from './components/Contact'
import RequestSms from './components/RequestSms'
import CheckCode from './components/CheckCode'
import ResetPassword from './components/ResetPassword'

import { PageContainer, PageContent } from './styles'

const ForgotPasswordPage: React.FC = () => {
  const [step, setStep] = useState<number>(0)

  const renderForgotPasswordSteps = (): React.ReactNode => {
    switch (step) {
      case 0:
        return <RequestSms setStep={setStep} />
      case 1:
        return <Contact />
      case 2:
        return <CheckCode setStep={setStep} />
      case 3:
        return <ResetPassword />
      default:
        return <></>
    }
  }
  return (
    <PageContainer>
      <Header />
      <PageContent>{renderForgotPasswordSteps()}</PageContent>
      <Footer />
    </PageContainer>
  )
}

export default ForgotPasswordPage
