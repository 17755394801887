/* eslint-disable */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import BiPage from '../pages/BiPage'
import CreateUserPage from '../pages/CreateUserPage'
import FileList from '../pages/FileList'
import ForgotPasswordPage from '../pages/ForgotPasswordPage'
import GalleryPage from '../pages/GalleryPage'
import LandingPage from '../pages/LandingPage'
import NotFoundPage from '../pages/NotFoundPage'
import ProjectPage from '../pages/ProjectPage'
import SignInPage from '../pages/SignInPage'
import SwotPage from '../pages/SwotPage'
import TimelinePage from '../pages/TimelinePage'
import { getUserInfo } from '../store/modules/user/getUserInfo/actions'
import { RootState } from '../store/rootReducers'
import LoginRequiredRoute from './LoginRequiredRoute'
import PrivateRoute from './PrivateRoute'

const Routes: React.FC = () => {
  const dispatch = useDispatch()

  const { signed, token } = useSelector((state: RootState) => state.signIn)
  const { getUserInfoLoading, userInfo } = useSelector(
    (state: RootState) => state.getUserInfo
  )

  const routes = [
    {
      exact: true,
      path: '/',
      component: LandingPage,
      private: false
    },
    {
      exact: true,
      path: '/authentication/signin',
      component: SignInPage,
      private: false
    },
    {
      exact: true,
      path: '/files',
      component: FileList,
      userVisible: true
    },
    {
      exact: true,
      path: '/authentication/forgotPassword',
      component: ForgotPasswordPage,
      private: false
    },
    {
      exact: true,
      path: '/projectpage',
      component: ProjectPage,
      private: true
    },
    {
      exact: true,
      path: '/bi',
      component: BiPage,
      private: true
    },
    {
      exact: true,
      path: '/timeline',
      component: TimelinePage,
      private: true
    },
    {
      exact: true,
      path: '/swotpage',
      component: SwotPage,
      private: true
    },
    {
      exact: true,
      path: '/gallery',
      component: GalleryPage,
      private: true
    },
    {
      exact: true,
      path: '/newuser',
      component: CreateUserPage,
      private: true
    }
  ]

  useEffect(() => {
    if (signed && token && !getUserInfoLoading && userInfo === null) {
      dispatch(getUserInfo(token))
    }
  }, [])

  return (
    <Router>
      <Switch>
        {routes.map((item, index) => {
          if (item.userVisible) {
            return <LoginRequiredRoute {...item} key={index} />
          }
          if (item.private) {
            return <PrivateRoute {...item} key={index} />
          } else {
            return (
              <Route
                exact={item.exact}
                path={item.path}
                component={item.component}
                key={index}
              />
            )
          }
        })}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default Routes
