import {
  RequestSmsActionTypes,
  RequestSmsFailureResponse,
  RequestSmsSuccessResponse,
  REQUEST_SMS_ERASE,
  REQUEST_SMS_FAILURE,
  REQUEST_SMS_REQUEST,
  REQUEST_SMS_SUCCESS
} from './types'

export function requestSms(mail: string): RequestSmsActionTypes {
  return {
    type: REQUEST_SMS_REQUEST,
    mail
  }
}

export function requestSmsSuccess(
  response: RequestSmsSuccessResponse
): RequestSmsActionTypes {
  return {
    type: REQUEST_SMS_SUCCESS,
    response
  }
}

export function requestSmsFailure(
  response: RequestSmsFailureResponse
): RequestSmsActionTypes {
  return {
    type: REQUEST_SMS_FAILURE,
    response
  }
}

export function requestSmsErase(): RequestSmsActionTypes {
  return {
    type: REQUEST_SMS_ERASE
  }
}
