import styled from 'styled-components'

interface TagContainerProps {
  selected: boolean | undefined
}
export const Container = styled.div<TagContainerProps>`
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.selected === true ? 1 : 0.5)};
`
export const TagContent = styled.span`
  font-size: 12px;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
`
