import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { updateUserAvatarFailure, updateUserAvatarSuccess } from './actions'
import { UPDATE_USER_AVATAR_REQUEST } from './types'

export function* updateUserAvatar(requestPayload: AnyAction) {
  try {
    const { image, token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    yield call(api.patch, 'user/updateImage', image, config)

    yield put(updateUserAvatarSuccess())
  } catch {
    yield put(updateUserAvatarFailure())
  }
}

export default all([takeLatest(UPDATE_USER_AVATAR_REQUEST, updateUserAvatar)])
