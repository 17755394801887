import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import { IoMdLock } from 'react-icons/io'

import { RootState } from '../../../../store/rootReducers'
import PrimaryButton from '../../../../components/PrimaryButton'
import LoadingScreen from '../../../../components/LoadingScreen'
import { resetPassword } from '../../../../store/modules/authentication/forgotPassword/resetPassword/actions'

import {
  Container,
  SignInPwdInput,
  SubmitButtonContainer,
  Error,
  FormContainer,
  SubmitButtonBlock
} from './styles'

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [pwd, setPwd] = useState<string>('')
  const [rPwd, setRPwd] = useState<string>('')

  const { code } = useSelector((state: RootState) => state.requestSms)
  const {
    resetPasswordLoading,
    resetPasswordFail,
    resetPasswordError
  } = useSelector((state: RootState) => state.resetPassword)

  const isPwdEquals: boolean = pwd === rPwd

  const isNotAllowedToSubmit: boolean =
    !isPwdEquals || pwd === '' || rPwd === '' || !!resetPasswordLoading

  const handleNavigateToSignIn = useCallback(() => {
    push('/authentication/signin')
  }, [])

  const handleResetPasswordSubmit = useCallback(() => {
    if (code && !isNotAllowedToSubmit) {
      dispatch(resetPassword(code, pwd))
    }
  }, [])

  useEffect(() => {
    if (resetPasswordLoading === false && resetPasswordFail === false) {
      handleNavigateToSignIn()
    }
  }, [resetPasswordLoading, resetPasswordFail])

  if (resetPasswordLoading) {
    return <LoadingScreen />
  }

  return (
    <Container>
      <h1>Digite sua nova senha</h1>

      <Form name="resetPwd" style={{ width: '100%' }}>
        <FormContainer>
          <Form.Item
            name="pwd"
            rules={[
              { required: true, message: 'Insira sua nova senha' },
              { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
            ]}
          >
            <SignInPwdInput
              placeholder="Nova senha"
              prefix={<IoMdLock color="#CCCCCC" />}
              value={pwd}
              onChange={e => setPwd(e.target.value)}
              type="password"
            />
          </Form.Item>

          <Form.Item
            name="rPwd"
            rules={[
              { required: true, message: 'Digite novamente sua nova senha' },
              { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
            ]}
          >
            <SignInPwdInput
              placeholder="Repita a senha"
              prefix={<IoMdLock color="#CCCCCC" />}
              value={rPwd}
              onChange={e => setRPwd(e.target.value)}
              type="password"
            />
          </Form.Item>

          <SubmitButtonContainer>
            {!isPwdEquals && (
              <Error>
                <span>Senhas diferentes</span>
              </Error>
            )}

            {resetPasswordFail && !!resetPasswordError && (
              <Error>
                <span>{resetPasswordError}</span>
              </Error>
            )}

            <SubmitButtonBlock>
              <PrimaryButton
                label="Trocar senha"
                onClick={handleResetPasswordSubmit}
                disabled={isNotAllowedToSubmit}
              />
            </SubmitButtonBlock>
          </SubmitButtonContainer>
        </FormContainer>
      </Form>
    </Container>
  )
}

export default ResetPassword
