import styled from 'styled-components'

export const Container = styled.div``
export const SwotModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

export const SwotModalRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
`

export const SwotModalTextArea = styled.textarea`
  height: 120px;
  outline: 0;
  width: 280px;
  border: none;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  resize: none;
`

export const SwotAddButton = styled.div``
export const SwotModalDropColumn = styled.div`
  margin-left: 10px;
  flex-direction: column;
  display: flex;
`
