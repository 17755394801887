import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../../services/api'
import { requestSmsFailure, requestSmsSuccess } from './actions'
import { RequestSmsAction, REQUEST_SMS_REQUEST } from './types'

export function* requestSms({ mail }: RequestSmsAction) {
  try {
    const response = yield call(api.post, 'user/forgotPassword', { email: mail })

    const { data } = response

    yield put(requestSmsSuccess({ ...data, mail }))
  } catch (err) {
    const { message } = err.response.data

    yield put(requestSmsFailure({ message }))
  }
}

export default all([takeLatest(REQUEST_SMS_REQUEST, requestSms)])
