import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdEye, IoMdTrash } from 'react-icons/io'

import { PhotoType } from '../../store/modules/gallery/getPhotos/types'
import NewModal from '../NewModal'
import { RootState } from '../../store/rootReducers'
import { deletePhoto } from '../../store/modules/gallery/deletePhoto/actions'
import AddButton from '../AddButton'

import { Container, OverlayBlock, PreviewImage, AddButtonBlock } from './styles'

interface Props {
  item: PhotoType
}

const PhotoCard: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch()

  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(
    false
  )
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(
    false
  )

  const { token } = useSelector((state: RootState) => state.signIn)
  const { deleteEventLoading } = useSelector(
    (state: RootState) => state.deleteEvent
  )

  const togglePreviewModalVisibility = (): void =>
    setIsPreviewModalVisible(previousState => !previousState)

  const toggleDeleteModalVisibility = (): void =>
    setIsDeleteModalVisible(previousState => !previousState)

  const renderPreviewModal = (): React.ReactNode => {
    if (isPreviewModalVisible) {
      return (
        <NewModal
          title="Preview da Imagem"
          visible={isPreviewModalVisible}
          onCancel={togglePreviewModalVisibility}
        >
          <PreviewImage src={item.filePath} alt={item._id} />
        </NewModal>
      )
    }

    return <></>
  }

  const handleDeletePhoto = (): void => {
    if (token && !deleteEventLoading) {
      dispatch(deletePhoto(item._id, token))

      toggleDeleteModalVisibility()
    }
  }

  const renderDeleteModal = (): React.ReactNode => {
    if (isDeleteModalVisible) {
      return (
        <NewModal
          title="Exclusão da imagem"
          visible={isDeleteModalVisible}
          onCancel={toggleDeleteModalVisibility}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Tem certeza que deseja excluir esta imagem?</span>
            <AddButtonBlock>
              <AddButton label="Excluir" onClickEvent={handleDeletePhoto} />
            </AddButtonBlock>
          </div>
        </NewModal>
      )
    }

    return <></>
  }

  return (
    <>
      <Container>
        <img src={item.filePath} alt={item._id} />
        <OverlayBlock>
          <button onClick={togglePreviewModalVisibility}>
            <IoMdEye color="#fff" size={32} />
          </button>
          <button onClick={toggleDeleteModalVisibility}>
            <IoMdTrash color="#fff" size={32} />
          </button>
        </OverlayBlock>
      </Container>
      {renderPreviewModal()}
      {renderDeleteModal()}
    </>
  )
}

export default PhotoCard
