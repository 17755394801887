import styled from 'styled-components'

export const SecondHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6%;
  justify-content: space-between;
  background-color: var(--primary);
  height: 80px;
`

export const SecondHeaderText = styled.span`
  color: #fff;
  font-size: 28px;
  font-family: var(--font);

  @media (max-width: 430px) {
    & {
      font-size: 24px;
    }
  }
`
