import React, { useCallback } from 'react'
import { Result } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PrimaryButton from '../../components/PrimaryButton'
import { RootState } from '../../store/rootReducers'

import { PageContainer, Container } from './styles'

const NotFoundPage: React.FC = () => {
  const { push } = useHistory()

  const { signed } = useSelector((state: RootState) => state.signIn)

  const handleNavigate = useCallback(() => {
    if (signed) {
      push('/projectpage')
    } else {
      push('/authentication/signin')
    }
  }, [signed])

  return (
    <PageContainer>
      <Header />
      <Container>
        <Result
          status="404"
          title="404"
          subTitle="Desculpe, porem está página não existe!"
          extra={<PrimaryButton label="Voltar" onClick={handleNavigate} />}
        />
      </Container>
      <Footer />
    </PageContainer>
  )
}

export default NotFoundPage
