export const UPDATE_USER_AVATAR_REQUEST = '@user/UPDATE_USER_AVATAR_REQUEST'
export const UPDATE_USER_AVATAR_SUCCESS = '@user/UPDATE_USER_AVATAR_SUCCESS'
export const UPDATE_USER_AVATAR_FAILURE = '@user/UPDATE_USER_AVATAR_FAILURE'

export const UPDATE_USER_AVATAR_ERASE = '@user/UPDATE_USER_AVATAR_ERASE'

export type UpdateUserAvatarState = {
  updateUserAvatarLoading: boolean | null
  updateUserAvatarFail: boolean | null
}

type UpdateUserAvatarAction = {
  type: typeof UPDATE_USER_AVATAR_REQUEST
  image: FormData
  token: string
}

type UpdateUserAvatarSuccessAction = {
  type: typeof UPDATE_USER_AVATAR_SUCCESS
}

type UpdateUserAvatarFailureAction = {
  type: typeof UPDATE_USER_AVATAR_FAILURE
}

type UpdateUserAvatarEraseAction = {
  type: typeof UPDATE_USER_AVATAR_ERASE
}

export type UpdateUserAvatarActionTypes =
  | UpdateUserAvatarAction
  | UpdateUserAvatarSuccessAction
  | UpdateUserAvatarFailureAction
  | UpdateUserAvatarEraseAction
