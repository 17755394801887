import produce from 'immer'

import {
  DeletePhotoState,
  DeletePhotoActionTypes,
  DELETE_PHOTO_REQUEST,
  DELETE_PHOTO_SUCCESS,
  DELETE_PHOTO_FAILURE,
  DELETE_PHOTO_ERASE
} from './types'

const INITIAL_STATE: DeletePhotoState = {
  deletePhotoLoading: null,
  deletePhotoFail: null
}

export default function deletePhoto(
  state = INITIAL_STATE,
  action: DeletePhotoActionTypes
): DeletePhotoState {
  switch (action.type) {
    case DELETE_PHOTO_REQUEST:
      return produce(state, draft => {
        draft.deletePhotoLoading = true
        draft.deletePhotoFail = INITIAL_STATE.deletePhotoFail
      })
    case DELETE_PHOTO_SUCCESS:
      return produce(state, draft => {
        draft.deletePhotoLoading = false
        draft.deletePhotoFail = false
      })
    case DELETE_PHOTO_FAILURE:
      return produce(state, draft => {
        draft.deletePhotoLoading = false
        draft.deletePhotoFail = true
      })
    case DELETE_PHOTO_ERASE:
      return produce(state, draft => {
        draft.deletePhotoLoading = INITIAL_STATE.deletePhotoLoading
        draft.deletePhotoFail = INITIAL_STATE.deletePhotoFail
      })
    default:
      return state
  }
}
