import {
  PostPhotoActionTypes,
  POST_PHOTO_ERASE,
  POST_PHOTO_FAILURE,
  POST_PHOTO_REQUEST,
  POST_PHOTO_SUCCESS
} from './types'

export function postPhoto(
  photo: FormData,
  token: string
): PostPhotoActionTypes {
  return {
    type: POST_PHOTO_REQUEST,
    photo,
    token
  }
}

export function postPhotoSuccess(): PostPhotoActionTypes {
  return {
    type: POST_PHOTO_SUCCESS
  }
}

export function postPhotoFailure(): PostPhotoActionTypes {
  return {
    type: POST_PHOTO_FAILURE
  }
}

export function postPhotoErase(): PostPhotoActionTypes {
  return {
    type: POST_PHOTO_ERASE
  }
}
