export const DELETE_EVENT_REQUEST = '@events/DELETE_EVENT_REQUEST'
export const DELETE_EVENT_SUCCESS = '@events/DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = '@events/DELETE_EVENT_FAILURE'

export const DELETE_EVENT_ERASE = '@events/DELETE_EVENT_ERASE'

export type DeleteEventState = {
  deleteEventLoading: boolean | null
  deleteEventFail: boolean | null
}

export type DeleteEventAction = {
  type: typeof DELETE_EVENT_REQUEST
  _id: string
  token: string
}

export type DeleteEventSuccessAction = {
  type: typeof DELETE_EVENT_SUCCESS
}

export type DeleteEventFailureAction = {
  type: typeof DELETE_EVENT_FAILURE
}

export type DeleteEventEraseAction = {
  type: typeof DELETE_EVENT_ERASE
}

export type DeleteEventActionTypes =
  | DeleteEventAction
  | DeleteEventSuccessAction
  | DeleteEventFailureAction
  | DeleteEventEraseAction
