import produce from 'immer'

import {
  ResetPasswordState,
  ResetPasswordActionTypes,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_ERASE
} from './types'

const INITIAL_STATE: ResetPasswordState = {
  resetPasswordLoading: null,
  resetPasswordFail: null,
  resetPasswordError: null
}

export default function resetPassword(
  state = INITIAL_STATE,
  action: ResetPasswordActionTypes
): ResetPasswordState {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST:
        draft.resetPasswordLoading = true
        draft.resetPasswordError = INITIAL_STATE.resetPasswordError
        draft.resetPasswordFail = INITIAL_STATE.resetPasswordFail
        break
      case RESET_PASSWORD_SUCCESS:
        draft.resetPasswordLoading = false
        draft.resetPasswordFail = false
        break
      case RESET_PASSWORD_FAILURE:
        draft.resetPasswordLoading = false
        draft.resetPasswordFail = true
        draft.resetPasswordError = action.response.message
        break
      case RESET_PASSWORD_ERASE:
        draft.resetPasswordLoading = INITIAL_STATE.resetPasswordLoading
        draft.resetPasswordError = INITIAL_STATE.resetPasswordError
        draft.resetPasswordFail = INITIAL_STATE.resetPasswordFail
        break
      default:
        return state
    }
  })
}
