export const UPDATE_USER_REQUEST = '@user/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = '@user/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = '@user/UPDATE_USER_FAILURE'

export const UPDATE_USER_ERASE = '@user/UPDATE_USER_ERASE'

export type UpdateUserPayload = {
  email?: string
  name?: string
  cellphone?: string
}

export type UpdateUserInfoState = {
  updateUserLoading: boolean | null
  updateUserFail: boolean | null
}

type UpdateUserAction = {
  type: typeof UPDATE_USER_REQUEST
  payload: UpdateUserPayload
  token: string
}

type UpdateUserSuccessAction = {
  type: typeof UPDATE_USER_SUCCESS
}

type UpdateUserFailureAction = {
  type: typeof UPDATE_USER_FAILURE
}

type UpdateUserEraseAction = {
  type: typeof UPDATE_USER_ERASE
}

export type UpdateUserActionTypes =
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserFailureAction
  | UpdateUserEraseAction
