import {
  ChangePwdActionTypes,
  ChangePwdPayload,
  CHANGE_PWD_ERASE,
  CHANGE_PWD_FAILURE,
  CHANGE_PWD_REQUEST,
  CHANGE_PWD_SUCCESS
} from './types'

export function changePwd(
  payload: ChangePwdPayload,
  token: string
): ChangePwdActionTypes {
  return {
    type: CHANGE_PWD_REQUEST,
    payload,
    token
  }
}

export function changePwdSuccess(): ChangePwdActionTypes {
  return {
    type: CHANGE_PWD_SUCCESS
  }
}

export function changePwdFailure(): ChangePwdActionTypes {
  return {
    type: CHANGE_PWD_FAILURE
  }
}

export function changePwdErase(): ChangePwdActionTypes {
  return {
    type: CHANGE_PWD_ERASE
  }
}
