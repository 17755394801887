export const CHANGE_PWD_REQUEST = '@user/CHANGE_PWD_REQUEST'
export const CHANGE_PWD_SUCCESS = '@user/CHANGE_PWD_SUCCESS'
export const CHANGE_PWD_FAILURE = '@user/CHANGE_PWD_FAILURE'

export const CHANGE_PWD_ERASE = '@user/CHANGE_PWD_ERASE'

export type ChangePwdState = {
  changePwdLoading: boolean | null
  changePwdFail: boolean | null
}

export type ChangePwdPayload = {
  password: string
  newPassword: string
}

type ChangePwdAction = {
  type: typeof CHANGE_PWD_REQUEST
  payload: ChangePwdPayload
  token: string
}

type ChangePwdSuccessAction = {
  type: typeof CHANGE_PWD_SUCCESS
}

type ChangePwdFailureAction = {
  type: typeof CHANGE_PWD_FAILURE
}

type ChangePwdEraseAction = {
  type: typeof CHANGE_PWD_ERASE
}

export type ChangePwdActionTypes =
  | ChangePwdAction
  | ChangePwdSuccessAction
  | ChangePwdFailureAction
  | ChangePwdEraseAction
