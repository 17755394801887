import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import api from '../../../../services/api'
import { getUserInfo } from '../getUserInfo/actions'
import { updateUserInfoFailure, updateUserInfoSuccess } from './actions'
import { UPDATE_USER_REQUEST } from './types'

export function* updateUserInfo(requestPayload: AnyAction) {
  try {
    const { payload, token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    yield call(api.patch, 'user/update', payload, config)

    yield put(updateUserInfoSuccess())
    yield put(getUserInfo(token))
  } catch (err) {
    yield put(updateUserInfoFailure())

    const { message } = err.response.data

    notification.error({ message })
  }
}

export default all([takeLatest(UPDATE_USER_REQUEST, updateUserInfo)])
