import styled from 'styled-components'

export const HeaderContainer = styled.div`
  padding: 12px;
  background-color: var(--primary);
`

export const TitleText = styled.span`
  color: #fff;
  font-size: 20px;
`

export const BodyContainer = styled.div`
  padding: 12px;
`
