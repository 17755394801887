import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%;
  background-color: #fff;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`

export const LogoImage = styled.img`
  height: 74px;

  @media (max-width: 430px) {
    width: 140px;
  }
`

export const MenuTitleText = styled.span`
  color: #888888;
  font-size: 16px;
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 60px;

  @media (max-width: 1050px) {
    & {
      padding-left: 30px;
    }
  }

  @media (max-width: 900px) {
    & {
      padding-left: 0;
    }
  }
`

export const IconBlock = styled.div`
  margin: 0 5px;
  cursor: pointer;
`

export const NavigateIconBlock = styled(IconBlock)`
  display: none;

  @media (max-width: 870px) {
    & {
      display: initial;
    }
  }
`

export const PopoverContentBlock = styled.div`
  display: flex;
  flex-direction: column;
`
