import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 0;
`

export const IconBlock = styled.div`
  padding-right: 5px;
`

export const LabelText = styled.span``
