/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useSWR, { ConfigInterface } from 'swr'
import api from '../services/api'

export function useFetch<Data = any, Error = unknown>(
  url: string,
  token: string | null,
  config?: ConfigInterface
) {
  const { data, error } = useSWR<Data, Error>(
    url,
    async url => {
      const response = await api.get(url, {
        headers: {
          authorization: token
        }
      })

      return response.data
    },
    config
  )

  return { data, error }
}
