import {
  GetUserInfoActionTypes,
  GET_USER_INFO_ERASE,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  UserInfoType
} from './types'

export function getUserInfo(token: string): GetUserInfoActionTypes {
  return {
    type: GET_USER_INFO_REQUEST,
    token
  }
}

export function getUserInfoSuccess(
  response: UserInfoType
): GetUserInfoActionTypes {
  return {
    type: GET_USER_INFO_SUCCESS,
    response
  }
}

export function getUserInfoFailure(): GetUserInfoActionTypes {
  return {
    type: GET_USER_INFO_FAILURE
  }
}

export function getUserInfoErase(): GetUserInfoActionTypes {
  return {
    type: GET_USER_INFO_ERASE
  }
}
