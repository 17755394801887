export const GET_USER_INFO_REQUEST = '@user/GET_USER_INFO_REQUEST'
export const GET_USER_INFO_SUCCESS = '@user/GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAILURE = '@user/GET_USER_INFO_FAILURE'

export const GET_USER_INFO_ERASE = '@user/GET_USER_INFO_ERASE'

interface Role {
  _id: string
  name: string
}
export type UserInfoType = {
  _id: string
  name: string
  email: string
  role: Role
  cellphone: string | undefined
  createdAt: string
  updatedAt: string
  image: string
}

export type GetUserInfoState = {
  getUserInfoLoading: boolean | null
  getUserInfoFail: boolean | null
  userInfo: UserInfoType | null
}

type GetUserInfoAction = {
  type: typeof GET_USER_INFO_REQUEST
  token: string
}

type GetUserInfoSuccessAction = {
  type: typeof GET_USER_INFO_SUCCESS
  response: UserInfoType
}

type GetUserInfoFailureAction = {
  type: typeof GET_USER_INFO_FAILURE
}

type GetUserInfoEraseAction = {
  type: typeof GET_USER_INFO_ERASE
}

export type GetUserInfoActionTypes =
  | GetUserInfoAction
  | GetUserInfoSuccessAction
  | GetUserInfoFailureAction
  | GetUserInfoEraseAction
