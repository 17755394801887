import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const PageContent = styled.div`
  height: 72%;
  overflow-y: auto;
`

export const ImagesList = styled.div`
  margin: 3% 6% 0 6%;
  display: grid;
  width: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 30px;

  @media (max-width: 1430px) {
    & {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 870px) {
    & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {
    & {
      grid-template-columns: 1fr;
    }
  }
`

export const CenteredView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
