import produce from 'immer'

import {
  RequestSmsActionTypes,
  RequestSmsState,
  REQUEST_SMS_ERASE,
  REQUEST_SMS_FAILURE,
  REQUEST_SMS_REQUEST,
  REQUEST_SMS_SUCCESS
} from './types'

const INITIAL_STATE: RequestSmsState = {
  requestSmsLoading: null,
  requestSmsFail: null,
  requestSmsError: null,
  cellphone: null,
  code: null,
  mail: null,
  countdown: null
}

export default function requestSms(
  state = INITIAL_STATE,
  action: RequestSmsActionTypes
): RequestSmsState {
  return produce(state, draft => {
    switch (action.type) {
      case REQUEST_SMS_REQUEST:
        draft.requestSmsLoading = true
        draft.requestSmsFail = INITIAL_STATE.requestSmsFail
        draft.requestSmsError = INITIAL_STATE.requestSmsError
        draft.code = INITIAL_STATE.code
        draft.cellphone = INITIAL_STATE.cellphone
        draft.mail = INITIAL_STATE.mail
        draft.countdown = INITIAL_STATE.countdown
        break
      case REQUEST_SMS_SUCCESS:
        draft.requestSmsLoading = false
        draft.requestSmsFail = false
        draft.code = action.response.code
        draft.cellphone = action.response.cellphone
        draft.mail = action.response.mail
        draft.countdown = action.response.countdown
        break
      case REQUEST_SMS_FAILURE:
        draft.requestSmsLoading = false
        draft.requestSmsFail = true
        draft.requestSmsError = action.response.message
        break
      case REQUEST_SMS_ERASE:
        draft.requestSmsLoading = INITIAL_STATE.requestSmsLoading
        draft.requestSmsFail = INITIAL_STATE.requestSmsFail
        draft.requestSmsError = INITIAL_STATE.requestSmsError
        draft.code = INITIAL_STATE.code
        draft.cellphone = INITIAL_STATE.cellphone
        draft.mail = INITIAL_STATE.mail
        draft.countdown = INITIAL_STATE.countdown
        break
      default:
        return state
    }
  })
}
