/* eslint-disable generator-star-spacing */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { call, put, all, takeLatest } from 'redux-saga/effects'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import api from '../../../../services/api'
import { postEventSuccess, postEventFailure } from './actions'
import { getEvents } from '../getEvents/actions'
import { POST_EVENT_REQUEST } from './types'

export function* postEvent(payload: AnyAction) {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: payload.token
      }
    }

    const response: AxiosResponse = yield call(
      api.post,
      'timeline/create',
      payload.payload,
      config
    )

    const { data } = response

    yield put(postEventSuccess(data))
    yield put(getEvents(payload.token))
  } catch (err) {
    yield put(postEventFailure())
  }
}

export default all([takeLatest(POST_EVENT_REQUEST, postEvent)])
