import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import AddButton from '../AddButton'
import { RootState } from '../../store/rootReducers'
import { UpdateEventPayload } from '../../store/modules/events/updateEvent/types'
import { updateEvent } from '../../store/modules/events/updateEvent/actions'
import NewModal from '../NewModal'

import {
  Container,
  DatePickerInput,
  RightContainer,
  TextAreaInput
} from './styles'

interface Props {
  year: string
  _id: string
  description: string
  visible: boolean
  onCancel: () => void
}

const EditEventModal: React.FC<Props> = ({
  year,
  _id,
  description,
  visible,
  onCancel
}) => {
  const dispatch = useDispatch()

  const [yearValue, setYearValue] = useState<Moment | null>(
    moment(new Date().setFullYear(Number(year)))
  )
  const [descriptionValue, setDescriptionValue] = useState<string>(description)

  const { token } = useSelector((state: RootState) => state.signIn)
  const { updateEventLoading } = useSelector(
    (state: RootState) => state.updateEvent
  )

  const isNotAllowedToSubmit: boolean =
    yearValue === null ||
    (descriptionValue === description &&
      moment(yearValue).year().toString() === year)

  const handleUpdateEventSubmit = (): void => {
    if (!isNotAllowedToSubmit && !updateEventLoading && token) {
      const payload: UpdateEventPayload = {
        _id,
        description: descriptionValue,
        year: moment(yearValue).year().toString()
      }

      dispatch(updateEvent(payload, token))
    }
  }

  return (
    <NewModal title="Editar evento" visible={visible} onCancel={onCancel}>
      <Container>
        <TextAreaInput
          placeholder="Descrição"
          value={descriptionValue}
          onChange={e => setDescriptionValue(e.target.value)}
        />
        <RightContainer>
          <DatePickerInput
            placeholder="Ano"
            value={yearValue}
            picker="year"
            onChange={value => {
              setYearValue(value)
            }}
          />
          <AddButton
            label="Editar"
            onClickEvent={handleUpdateEventSubmit}
            disabled={isNotAllowedToSubmit}
          />
        </RightContainer>
      </Container>
    </NewModal>
  )
}

export default EditEventModal
