import { combineReducers } from 'redux'
import checkCode from './modules/authentication/forgotPassword/checkCode/reducer'
import requestSms from './modules/authentication/forgotPassword/requestSms/reducer'
import resetPassword from './modules/authentication/forgotPassword/resetPassword/reducer'
import signIn from './modules/authentication/signIn/reducer'
import { SIGN_OUT } from './modules/authentication/signIn/types'
import deleteEvent from './modules/events/deleteEvent/reducer'
import getEvents from './modules/events/getEvents/reducer'
import postEvent from './modules/events/postEvent/reducer'
import updateEvent from './modules/events/updateEvent/reducer'
import deletePhoto from './modules/gallery/deletePhoto/reducer'
import getPhotos from './modules/gallery/getPhotos/reducer'
import postPhoto from './modules/gallery/postPhoto/reducer'
import changePwd from './modules/user/changePwd/reducer'
import getUserInfo from './modules/user/getUserInfo/reducer'
import updateUserAvatar from './modules/user/updateUserAvatar/reducer'
import updateUserInfo from './modules/user/updateUserInfo/reducer'

export const allReducers = combineReducers({
  getEvents,
  signIn,
  postEvent,
  deleteEvent,
  updateEvent,
  getUserInfo,
  updateUserInfo,
  changePwd,
  updateUserAvatar,
  getPhotos,
  postPhoto,
  deletePhoto,
  requestSms,
  checkCode,
  resetPassword
})

export type RootState = ReturnType<typeof allReducers>

const rootReducers = (state: any, action: any): RootState => {
  if (action.type === SIGN_OUT) {
    window.location.replace('/')
    localStorage.removeItem('persist:root')
    state = undefined
  }

  return allReducers(state, action)
}

export default rootReducers
