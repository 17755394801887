import styled from 'styled-components'

export const NavigateButtonsBlock = styled.div`
  display: flex;
  @media (max-width: 870px) {
    & {
      display: none;
    }
  }
`
