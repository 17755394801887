import styled from 'styled-components'
import { Button } from 'antd'

export const PageContainer = styled.div`
  width: 100vw;
  max-height: 100vh;
`

export const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;

  width: 100vw;
`
export const ProjectTableContainer = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  width: 80%;
`

export const RightSideContainer = styled.div`
  flex-direction: column;
  margin-left: 15px;
  width: 30vw;
`

export const ActivitesBox = styled.div`
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 35vh;
  max-width: 25vw;
  overflow-y: scroll;
`

export const ActivitiesContainer = styled.div``
export const ActivitiesInnerContainer = styled.div`
  margin-top: 10px;
  border-top: 1px solid black;
`

export const ActivitiesTitle = styled.span`
  font-size: 16px;
  color: black;
  font-weight: bold;
`

export const HeaderInner = styled.div`
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

export const ActionsContainer = styled.div`
  color: white;
  margin-right: 40%;
  white-space: nowrap;
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
`
export const ProjectModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`
export const ProjectModalRow = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`
export const ProjectModalTextHeader = styled.div`
  background-color: var(--primary);
  height: 50px;
  border-top-left-radius: 2px;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 5px;
`
export const ProjectModalTextTitle = styled.h2`
  font-style: italic;
  color: white;
`

export const ProjectModalTextArea = styled.textarea`
  height: 200px;
  outline: 0;
  width: 280px;
  border: none;
  padding: 10px;
  background-color: #fafafa;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  resize: none;
  margin-top: 5px;
`

export const ProjectModalDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProjectModalSelectionColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
`
export const TagListContainer = styled.div`
  max-width: 220px;
  justify-content: flex-end;
  display: inline-flex;
  height: 100%;
`
export const TagListInnerContainer = styled.div`
  min-height: 250px;
`

export const CreateTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

export const AddTagButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
`

export const TagDivider = styled.div`
  & + * {
    margin-top: 5px;
  }
`

export const SelectedTagsContainer = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: row;
  max-width: 250px;
  flex-wrap: wrap;
`

export const SecondHeaderContentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;

  @media (max-width: 820px) {
    & {
      width: initial;
      justify-content: initial;
    }
  }
`

export const ActionsBlock = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 580px) {
    display: none;
  }
`

export const ActionButton = styled(Button).attrs({ type: 'text' })`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
`
