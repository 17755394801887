/* eslint-disable generator-star-spacing */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { takeLatest, call, put, all } from 'redux-saga/effects'

import api from '../../../../services/api'
import { getEventsFailure, getEventsSuccess } from './actions'
import { GET_EVENTS_REQUEST } from './types'

export function* getEvents(payload: AnyAction) {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: payload.token
      }
    }

    const response: AxiosResponse = yield call(api.get, 'timelines', config)

    yield put(getEventsSuccess({ data: response.data }))
  } catch (err) {
    yield put(getEventsFailure())
  }
}

export default all([takeLatest(GET_EVENTS_REQUEST, getEvents)])
