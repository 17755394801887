import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 180px;

  border-radius: 20px;
  overflow: hidden;

  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`

export const OverlayBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 2em;
  opacity: 0;
  font-size: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);

  transition: opacity 0.3s ease;

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
`

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
`

export const AddButtonBlock = styled.div`
  padding-top: 10px;
  width: 140px;
  align-self: flex-end;
`
