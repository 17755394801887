import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { postPhotoFailure, postPhotoSuccess } from './actions'
import { POST_PHOTO_REQUEST } from './types'

export function* postPhoto(requestPayload: AnyAction) {
  try {
    const { photo, token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    yield call(api.post, 'gallery/create', photo, config)

    yield put(postPhotoSuccess())
  } catch {
    yield put(postPhotoFailure())
  }
}

export default all([takeLatest(POST_PHOTO_REQUEST, postPhoto)])
