import React from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import {
  AddButtonBlock,
  AddButtonBlockDisabled,
  AddButtonContainer,
  AddButtonText
} from './styles'
interface Props {
  onClickEvent: () => void
  label: string
  width?: number | string
  height?: number | string
  disabled?: boolean
  fontSize?: number | string
}

const AddButton: React.FC<Props> = ({
  onClickEvent,
  label,
  height,
  width,
  fontSize,
  disabled
}) => {
  if (disabled) {
    return (
      <AddButtonContainer>
        <AddButtonBlockDisabled style={{ height, width }}>
          <BiAddToQueue color="white" />
          <AddButtonText>{label}</AddButtonText>
        </AddButtonBlockDisabled>
      </AddButtonContainer>
    )
  }

  return (
    <AddButtonContainer>
      <AddButtonBlock onClick={onClickEvent} style={{ height, width }}>
        <BiAddToQueue color="white" />
        <AddButtonText style={{ fontSize: fontSize }}>{label}</AddButtonText>
      </AddButtonBlock>
    </AddButtonContainer>
  )
}

export default AddButton
