import { Empty } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddButton from '../../components/AddButton'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import PhotoCard from '../../components/PhotoCard'
import SecondHeader from '../../components/SecondHeader'
import UploadPhotoModal from '../../components/UploadPhotoModal'
import { getPhotos } from '../../store/modules/gallery/getPhotos/actions'
import { postPhotoErase } from '../../store/modules/gallery/postPhoto/actions'
import { RootState } from '../../store/rootReducers'

import { PageContainer, PageContent, ImagesList, CenteredView } from './styles'

const GalleryPage: React.FC = () => {
  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const { token } = useSelector((state: RootState) => state.signIn)
  const { getPhotosLoading, photos } = useSelector(
    (state: RootState) => state.getPhotos
  )
  const { postPhotoLoading, postPhotoFail } = useSelector(
    (state: RootState) => state.postPhoto
  )
  const { deletePhotoLoading } = useSelector(
    (state: RootState) => state.deletePhoto
  )

  const handleToggleModalVisibility = (): void =>
    setIsModalVisible(previousState => !previousState)

  const renderModal = (): React.ReactNode => {
    if (isModalVisible) {
      return (
        <UploadPhotoModal
          visible={isModalVisible}
          onCancel={handleToggleModalVisibility}
        />
      )
    }

    return <></>
  }

  const renderPageContent = (): React.ReactNode => {
    if (photos !== null && photos.length !== 0) {
      return (
        <ImagesList>
          {photos.map(photo => (
            <PhotoCard key={photo._id} item={photo} />
          ))}
        </ImagesList>
      )
    }

    return (
      <CenteredView>
        <Empty description="Nenhuma imagem encontrada!" />
      </CenteredView>
    )
  }

  useEffect(() => {
    if (token) {
      dispatch(getPhotos(token))
    }
  }, [])

  useEffect(() => {
    if (!postPhotoLoading && postPhotoFail === false && token) {
      dispatch(getPhotos(token))
      dispatch(postPhotoErase())
    }
  }, [postPhotoLoading, postPhotoFail])

  if (getPhotosLoading || postPhotoLoading || deletePhotoLoading) {
    return <LoadingScreen />
  }

  return (
    <PageContainer>
      <Header />
      <SecondHeader
        title="Galeria de Fotos"
        content={
          <AddButton
            label="Adicionar"
            onClickEvent={handleToggleModalVisibility}
          />
        }
      />
      <PageContent>{renderPageContent()}</PageContent>
      <Footer />
      {renderModal()}
    </PageContainer>
  )
}

export default GalleryPage
