import axios from 'axios'

const api = axios.create({
  baseURL: 'https://bkpi-equilibrio-api.herokuapp.com/'
  // baseURL: 'http://localhost:3333/'
})

api.interceptors.response.use(
  function (response) {
    return response
  },

  function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 500) {
      window.location.replace('/authentication/signin')
      window.localStorage.clear()
    } else {
      return error.response
    }
  }
)

export default api
