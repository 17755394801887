const phoneMask = (value: string | null): string => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d)(\d{4})(\d{1})/, '$1 $2-$3')
      .replace(/(\d{3})(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
  return ''
}

export default phoneMask
