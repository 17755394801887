import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { changePwdFailure, changePwdSuccess } from './actions'
import { CHANGE_PWD_REQUEST } from './types'

export function* changePwd(requestPayload: AnyAction) {
  try {
    const { payload, token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    yield call(api.patch, 'user/changePassword', payload, config)

    yield put(changePwdSuccess())
  } catch {
    yield put(changePwdFailure())
  }
}

export default all([takeLatest(CHANGE_PWD_REQUEST, changePwd)])
