import styled from 'styled-components'
import { Input } from 'antd'
import { CameraFilled } from '@ant-design/icons'

export const Container = styled.div`
  padding: 0 20px 20px 20px;
`

export const PersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PersonalFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`

export const AvatarDesc = styled.span`
  color: #888888;
  font-size: 12px;
  margin-top: -30px;
`

export const PersonalFormBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
`

export const InputLabel = styled.span`
  color: #888888;
  font-size: 12px;
`

export const PersonalDataInput = styled(Input)`
  width: 100%;
`

export const AddButtonBlock = styled.div`
  padding-top: 10px;
  width: 140px;
  align-self: flex-start;
`

export const ChangePwdContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ChangePwdInput = styled(Input.Password)`
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ErrorText = styled.span`
  color: #ff4d4f;
  margin-left: 20px;
`

export const CameraIcon = styled(CameraFilled)`
  font-size: 32px;
  position: relative;
  color: rgba(000, 000, 000, 0.5);
  bottom: 30px;
  left: 30px;
`
