import {
  GetPhotosActionTypes,
  GET_PHOTOS_ERASE,
  GET_PHOTOS_FAILURE,
  GET_PHOTOS_REQUEST,
  GET_PHOTOS_SUCCESS,
  PhotoType
} from './types'

export function getPhotos(token: string): GetPhotosActionTypes {
  return {
    type: GET_PHOTOS_REQUEST,
    token
  }
}

export function getPhotosSuccess(response: PhotoType[]): GetPhotosActionTypes {
  return {
    type: GET_PHOTOS_SUCCESS,
    response
  }
}

export function getPhotosFailure(): GetPhotosActionTypes {
  return {
    type: GET_PHOTOS_FAILURE
  }
}

export function getPhotosErase(): GetPhotosActionTypes {
  return {
    type: GET_PHOTOS_ERASE
  }
}
