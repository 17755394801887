import React from 'react'

import { Container, IconBlock, LabelText } from './styles'

interface Props {
  label: string
  icon: React.ReactNode
  onClickEvent: () => void
}

const PopoverItem: React.FC<Props> = ({ label, icon, onClickEvent }) => {
  return (
    <Container onClick={onClickEvent}>
      <IconBlock>{icon}</IconBlock>
      <LabelText>{label}</LabelText>
    </Container>
  )
}

export default PopoverItem
