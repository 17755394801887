import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 74px;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: flex-end;
  padding: 0 6%;
`

export const BkpiLogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const BkpiLogoText = styled.span`
  font-size: 12px;
  width: 130px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 6px;
  line-height: 22px;
`

export const BkpiLogo = styled.img``
