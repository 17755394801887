import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import PopoverItem from '../PopoverItem'
import { RootState } from '../../store/rootReducers'
import { deleteEvent } from '../../store/modules/events/deleteEvent/actions'
import EditEventModal from '../EditEventModal'

import {
  Container,
  EventText,
  MoreOptionsIconBlock,
  PopoverContainer
} from './styles'

interface Props {
  year: string
  _id: string
  description: string
}

const EventCard: React.FC<Props> = ({ year, _id, description }) => {
  const dispatch = useDispatch()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const { token } = useSelector((state: RootState) => state.signIn)

  const handleCloseModal = (): void => {
    setIsModalVisible(false)
  }

  const renderEditEventModal = (): React.ReactNode => {
    if (isModalVisible) {
      return (
        <EditEventModal
          _id={_id}
          description={description}
          year={year}
          visible={isModalVisible}
          onCancel={handleCloseModal}
        />
      )
    }

    return <></>
  }

  return (
    <Container>
      <EventText>{description}</EventText>
      <MoreOptionsIconBlock>
        <Dropdown
          overlay={
            <PopoverContainer>
              <PopoverItem
                label="Editar"
                icon={<EditOutlined />}
                onClickEvent={() => {
                  setIsModalVisible(true)
                  setIsVisible(false)
                }}
              />
              <PopoverItem
                label="Excluir"
                icon={<DeleteOutlined />}
                onClickEvent={() => {
                  if (token) dispatch(deleteEvent(_id, token))
                  setIsVisible(false)
                }}
              />
            </PopoverContainer>
          }
          trigger={['click']}
          visible={isVisible}
          onVisibleChange={visible => setIsVisible(visible)}
        >
          <MoreOutlined />
        </Dropdown>
      </MoreOptionsIconBlock>
      {renderEditEventModal()}
    </Container>
  )
}

export default EventCard
