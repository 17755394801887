import {
  ResetPasswordActionTypes,
  ResetPasswordFailureResponse,
  RESET_PASSWORD_ERASE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS
} from './types'

export function resetPassword(
  code: string,
  password: string
): ResetPasswordActionTypes {
  return {
    type: RESET_PASSWORD_REQUEST,
    code,
    password
  }
}

export function resetPasswordSuccess(): ResetPasswordActionTypes {
  return {
    type: RESET_PASSWORD_SUCCESS
  }
}

export function resetPasswordFailure(
  response: ResetPasswordFailureResponse
): ResetPasswordActionTypes {
  return {
    type: RESET_PASSWORD_FAILURE,
    response
  }
}

export function resetPasswordErase(): ResetPasswordActionTypes {
  return {
    type: RESET_PASSWORD_ERASE
  }
}
