export const CHECK_CODE_REQUEST = '@forgotPassword/CHECK_CODE_REQUEST'
export const CHECK_CODE_SUCCESS = '@forgotPassword/CHECK_CODE_SUCCESS'
export const CHECK_CODE_FAILURE = '@forgotPassword/CHECK_CODE_FAILURE'

export const CHECK_CODE_ERASE = '@forgotPassword/CHECK_CODE_ERASE'

export type CheckCodeState = {
  checkCodeLoading: boolean | null
  checkCodeFail: boolean | null
  checkCodeError: string | null
}

export type CheckCodeAction = {
  type: typeof CHECK_CODE_REQUEST
  code: string
  token: string
}

type CheckCodeSuccessAction = {
  type: typeof CHECK_CODE_SUCCESS
}

export type CheckCodeFailureResponse = {
  message: string
}

type CheckCodeFailureAction = {
  type: typeof CHECK_CODE_FAILURE
  response: CheckCodeFailureResponse
}

type CheckCodeEraseAction = {
  type: typeof CHECK_CODE_ERASE
}

export type CheckCodeActionTypes =
  | CheckCodeAction
  | CheckCodeSuccessAction
  | CheckCodeFailureAction
  | CheckCodeEraseAction
