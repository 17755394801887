import styled from 'styled-components'

export const Container = styled.div`
  display: inline-block;
`

export const EventText = styled.span`
  color: #888888;
  font-size: 14px;
  padding-bottom: 5px;
`

export const MoreOptionsIconBlock = styled.div`
  display: inline;
  padding-left: 5px;
  font-size: 18px;
`

export const PopoverContainer = styled.div`
  background-color: #fff;
  border: 1px solid #e4e4e4;
  padding: 5px;
  box-shadow: 0 0 6px rgb(90, 90, 90);
`
