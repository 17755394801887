import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SecondHeader from '../../components/SecondHeader'
import { BIFrame, PageContainer, PageContent } from './styles'

const BiPage: React.FC = () => {
  return (
    <PageContainer>
      <Header />
      <SecondHeader title="BI Equilíbrio" />
      <PageContent>
        <BIFrame
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiZDJlY2VkYzQtZWFmZC00NGViLTlhYzgtMDdlMDc5OTY5M2U0IiwidCI6ImE3NTZkZDg3LTcyOTItNGJmMS1hNDdhLTE4NWVjOTRjMmM3MCJ9"
        ></BIFrame>
      </PageContent>
      <Footer />
    </PageContainer>
  )
}

export default BiPage
