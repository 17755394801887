import {
  UpdateUserActionTypes,
  UpdateUserPayload,
  UPDATE_USER_ERASE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from './types'

export function updateUserInfo(
  payload: UpdateUserPayload,
  token: string
): UpdateUserActionTypes {
  return {
    type: UPDATE_USER_REQUEST,
    payload,
    token
  }
}

export function updateUserInfoSuccess(): UpdateUserActionTypes {
  return {
    type: UPDATE_USER_SUCCESS
  }
}

export function updateUserInfoFailure(): UpdateUserActionTypes {
  return {
    type: UPDATE_USER_FAILURE
  }
}

export function updateUserInfoErase(): UpdateUserActionTypes {
  return {
    type: UPDATE_USER_ERASE
  }
}
