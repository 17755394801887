export const DELETE_PHOTO_REQUEST = '@gallery/DELETE_PHOTO_REQUEST'
export const DELETE_PHOTO_SUCCESS = '@gallery/DELETE_PHOTO_SUCCESS'
export const DELETE_PHOTO_FAILURE = '@gallery/DELETE_PHOTO_FAILURE'

export const DELETE_PHOTO_ERASE = '@gallery/DELETE_PHOTO_ERASE'

export type DeletePhotoState = {
  deletePhotoLoading: boolean | null
  deletePhotoFail: boolean | null
}

type DeletePhotoAction = {
  type: typeof DELETE_PHOTO_REQUEST
  _id: string
  token: string
}

type DeletePhotoSuccessAction = {
  type: typeof DELETE_PHOTO_SUCCESS
}

type DeletePhotoFailureAction = {
  type: typeof DELETE_PHOTO_FAILURE
}

type DeletePhotoEraseAction = {
  type: typeof DELETE_PHOTO_ERASE
}

export type DeletePhotoActionTypes =
  | DeletePhotoAction
  | DeletePhotoSuccessAction
  | DeletePhotoFailureAction
  | DeletePhotoEraseAction
