import { call, put, all, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import api from '../../../../../services/api'
import { RESET_PASSWORD_REQUEST, ResetPasswordAction } from './types'
import { resetPasswordSuccess, resetPasswordFailure } from './actions'

export function* resetPassword({ code, password }: ResetPasswordAction) {
  try {
    yield call(api.patch, 'user/resetPassword', { code, password })

    yield put(resetPasswordSuccess())

    notification.success({
      duration: 3,
      message: 'Senha alterada com sucesso!'
    })
  } catch (err) {
    const { data } = err.response

    yield put(resetPasswordFailure(data))
  }
}

export default all([takeLatest(RESET_PASSWORD_REQUEST, resetPassword)])
