import React, { useState } from 'react'
import { Popover } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

import { Container, PopoverContent, ActionButton } from './styles'

interface Props {
  myProjectsClickEvent: () => void
  tagsClickEvent: () => void
}

const ActionsPopover: React.FC<Props> = ({
  myProjectsClickEvent,
  tagsClickEvent
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <Container>
      <Popover
        visible={isVisible}
        onVisibleChange={visible => setIsVisible(visible)}
        color="#2a569f"
        content={
          <PopoverContent>
            <ActionButton
              onClick={() => {
                myProjectsClickEvent()
                setIsVisible(false)
              }}
            >
              Meus projetos
            </ActionButton>
            <ActionButton
              onClick={() => {
                tagsClickEvent()
                setIsVisible(false)
              }}
            >
              Tags
            </ActionButton>
          </PopoverContent>
        }
      >
        <EllipsisOutlined style={{ fontSize: 28, color: '#fff' }} />
      </Popover>
    </Container>
  )
}

export default ActionsPopover
