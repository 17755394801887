import { call, put, all, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { AxiosRequestConfig } from 'axios'

import api from '../../../../services/api'
import { deleteEventSuccess, deleteEventFailure } from './actions'
import { getEvents } from '../getEvents/actions'
import { DELETE_EVENT_REQUEST } from './types'

export function* deleteEvent(payload: AnyAction) {
  try {
    const { _id, token } = payload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      },
      data: {
        _id
      }
    }

    yield call(api.delete, 'timeline/delete', config)

    yield put(deleteEventSuccess())
    yield put(getEvents(token))
  } catch (err) {
    yield put(deleteEventFailure())
  }
}

export default all([takeLatest(DELETE_EVENT_REQUEST, deleteEvent)])
