import styled from 'styled-components'
import { Button } from 'antd'

export const Container = styled.div`
  display: none;
  padding-right: 20px;

  @media (max-width: 580px) {
    & {
      display: initial;
    }
  }
`

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionButton = styled(Button).attrs({ type: 'text' })`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: left;
`
