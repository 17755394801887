import produce from 'immer'

import {
  UpdateEventActionTypes,
  UpdateEventState,
  UPDATE_EVENT_ERASE,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS
} from './types'

const INITIAL_STATE: UpdateEventState = {
  updateEventLoading: null,
  updateEventFail: null
}

export default function updateEvent(
  state = INITIAL_STATE,
  action: UpdateEventActionTypes
): UpdateEventState {
  switch (action.type) {
    case UPDATE_EVENT_REQUEST:
      return produce(state, draft => {
        draft.updateEventLoading = true
        draft.updateEventFail = INITIAL_STATE.updateEventFail
      })
    case UPDATE_EVENT_SUCCESS:
      return produce(state, draft => {
        draft.updateEventLoading = false
        draft.updateEventFail = false
      })
    case UPDATE_EVENT_FAILURE:
      return produce(state, draft => {
        draft.updateEventLoading = false
        draft.updateEventFail = true
      })
    case UPDATE_EVENT_ERASE:
      return produce(state, draft => {
        draft.updateEventLoading = INITIAL_STATE.updateEventLoading
        draft.updateEventFail = INITIAL_STATE.updateEventFail
      })
    default:
      return state
  }
}
