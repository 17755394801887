import React from 'react'
import { useHistory } from 'react-router-dom'

import PrimaryButton from '../../../../components/PrimaryButton'

import { Container, TextBlock, ButtonBlock } from './styles'

const Contact: React.FC = () => {
  const { push } = useHistory()

  function handleNavigateBack(): void {
    push('/authentication/signin')
  }

  return (
    <Container>
      <TextBlock>
        <span>
          Entre em contato com a BKPI pelo telefone{' '}
          <strong>(34) 99910-2608</strong> ou pelo e-mail{' '}
          <strong>bkpi@bkpi.io</strong>
        </span>
      </TextBlock>
      <ButtonBlock>
        <PrimaryButton label="Voltar" onClick={handleNavigateBack} />
      </ButtonBlock>
    </Container>
  )
}

export default Contact
