import {
  GetEventsActionTypes,
  GetEventsSuccessResponse,
  GET_EVENTS_ERASE,
  GET_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS
} from './types'

export function getEvents(token: string): GetEventsActionTypes {
  return {
    type: GET_EVENTS_REQUEST,
    token
  }
}

export function getEventsSuccess(
  response: GetEventsSuccessResponse
): GetEventsActionTypes {
  return {
    type: GET_EVENTS_SUCCESS,
    response
  }
}

export function getEventsFailure(): GetEventsActionTypes {
  return {
    type: GET_EVENTS_FAILURE
  }
}

export function getEventsErase(): GetEventsActionTypes {
  return {
    type: GET_EVENTS_ERASE
  }
}
