export const checkRole = (): boolean => {
  const role = localStorage.getItem('role')

  if (
    role === '6029ae67cf41f81e54135cfb' ||
    role === '6028f919e0e54f136a9b48af'
  ) {
    return true
  } else {
    return false
  }
}
