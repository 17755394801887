import {
  SignInPayload,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SignInActionTypes,
  SignInSuccessResponse,
  SignInFailureResponse
} from './types'

export function signIn(payload: SignInPayload): SignInActionTypes {
  return {
    type: SIGN_IN_REQUEST,
    payload
  }
}

export function signInSuccess(
  response: SignInSuccessResponse
): SignInActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    response
  }
}

export function signInFailure(
  response: SignInFailureResponse
): SignInActionTypes {
  return {
    type: SIGN_IN_FAILURE,
    response
  }
}

export function signOut(): SignInActionTypes {
  return {
    type: SIGN_OUT
  }
}
