export const SIGN_IN_REQUEST = '@authentication/SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = '@authentication/SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = '@authentication/SIGN_IN_FAILURE'
export const SIGN_OUT = '@authentication/SIGN_OUT'

export type SignInState = {
  signInLoading: boolean | null
  signInFail: boolean | null
  signInError: string | null
  signed: boolean
  token: string | null
}

export type SignInPayload = {
  email: string
  password: string
}

export type SignInSuccessResponse = {
  _id: string
  email: string
  createdAt: string
  updatedAt: string
  token: string
}

export type SignInFailureResponse = {
  message: string
}

export type SignInRequestAction = {
  type: typeof SIGN_IN_REQUEST
  payload: SignInPayload
}

export type SignInSuccessAction = {
  type: typeof SIGN_IN_SUCCESS
  response: SignInSuccessResponse
}

export type SignInFailureAction = {
  type: typeof SIGN_IN_FAILURE
  response: SignInFailureResponse
}

export type SignOutAction = {
  type: typeof SIGN_OUT
}

export type SignInActionTypes =
  | SignInRequestAction
  | SignOutAction
  | SignInSuccessAction
  | SignInFailureAction
