import styled from 'styled-components'
import { Input } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  text-align: center;

  @media (max-width: 700px) {
    width: 60%;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`

export const SignInPwdInput = styled(Input.Password)`
  height: 60px;
  font-size: 28px;
  border-radius: 30px;

  .ant-input {
    font-size: 22px;
  }

  .ant-input::placeholder {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
  }

  @media (max-width: 520px) {
    .ant-input {
      font-size: 18px;
    }

    .ant-input::placeholder {
      font-size: 18px;
    }
  }
`

export const Error = styled.div`
  margin-bottom: 20px;
  text-align: center;

  span {
    color: #c53030;
    font-weight: 500;
  }
`

export const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`

export const SubmitButtonBlock = styled.div`
  width: 100%;
`
