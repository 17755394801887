import { Popconfirm } from 'antd'
import React from 'react'
import { BiTrash } from 'react-icons/bi'
import { Container, TagContent } from './styles'
type Props = {
  width: number | string
  height: number | string
  content: string
  backgroundColor: string
  selected?: boolean
  card?: boolean
  editMode?: boolean
  editModeCallback?: () => void
}
const Tag: React.FC<Props> = ({
  width,
  height,
  backgroundColor,
  content,
  selected,
  card,
  editMode,
  editModeCallback
}) => {
  return (
    <Container
      selected={card ? true : !!selected}
      style={{
        minWidth: width,
        height,
        backgroundColor,
        textAlign: 'center',
        display: card ? '' : 'flex',
        justifyContent: card ? '' : 'space-between'
      }}
    >
      <TagContent>{content}</TagContent>
      {editMode ? (
        <Popconfirm
          title={
            <span>
              Tem certeza que desja excluir a tag: <strong>{content} </strong>?
            </span>
          }
          onConfirm={editModeCallback}
          okText="Sim, excluir"
          cancelText="Cancelar"
          okButtonProps={{ style: { border: 'none' } }}
        >
          <BiTrash size={18} color={'#fff'} />
        </Popconfirm>
      ) : null}
    </Container>
  )
}

export default Tag
