import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover } from 'antd'
import {
  IoMdApps,
  IoMdFolderOpen,
  IoMdImages,
  IoMdToday,
  IoMdAnalytics,
  IoMdEasel
} from 'react-icons/io'

import PopoverItem from '../PopoverItem'

import { MenuTitleText, PopoverContentBlock } from './styles'

const NavigatePopover: React.FC = () => {
  const { push } = useHistory()

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const handleNavigate = (pathname: string): void => {
    push(pathname)
    setIsVisible(false)
  }

  return (
    <Popover
      title={<MenuTitleText>Navegação</MenuTitleText>}
      visible={isVisible}
      onVisibleChange={visible => setIsVisible(visible)}
      content={
        <PopoverContentBlock>
          <PopoverItem
            label="Projetos"
            icon={<IoMdFolderOpen />}
            onClickEvent={() => handleNavigate('/projectpage')}
          />
          <PopoverItem
            label="SWOT"
            icon={<IoMdEasel />}
            onClickEvent={() => handleNavigate('/swotpage')}
          />
          <PopoverItem
            label="BI"
            icon={<IoMdAnalytics />}
            onClickEvent={() => handleNavigate('/bi')}
          />
          <PopoverItem
            label="Timeline"
            icon={<IoMdToday />}
            onClickEvent={() => handleNavigate('/timeline')}
          />
          <PopoverItem
            label="Fotos"
            icon={<IoMdImages />}
            onClickEvent={() => handleNavigate('/gallery')}
          />
        </PopoverContentBlock>
      }
    >
      <IoMdApps size={30} />
    </Popover>
  )
}

export default NavigatePopover
