export const REQUEST_SMS_REQUEST = '@forgotPassword/REQUEST_SMS_REQUEST'
export const REQUEST_SMS_SUCCESS = '@forgotPassword/REQUEST_SMS_SUCCESS'
export const REQUEST_SMS_FAILURE = '@forgotPassword/REQUEST_SMS_FAILURE'

export const REQUEST_SMS_ERASE = '@forgotPassword/REQUEST_SMS_ERASE'

export type RequestSmsState = {
  requestSmsLoading: boolean | null
  requestSmsFail: boolean | null
  requestSmsError: string | null
  code: string | null
  cellphone: string | null
  mail: string | null
  countdown: number | null
}

export type RequestSmsSuccessResponse = {
  code: string
  cellphone: string
  countdown: number
  mail: string
}

export type RequestSmsFailureResponse = {
  message: string
}

export type RequestSmsAction = {
  type: typeof REQUEST_SMS_REQUEST
  mail: string
}

type RequestSmsSuccessAction = {
  type: typeof REQUEST_SMS_SUCCESS
  response: RequestSmsSuccessResponse
}

type RequestSmsFailureAction = {
  type: typeof REQUEST_SMS_FAILURE
  response: RequestSmsFailureResponse
}

type RequestSmsEraseAction = {
  type: typeof REQUEST_SMS_ERASE
}

export type RequestSmsActionTypes =
  | RequestSmsAction
  | RequestSmsSuccessAction
  | RequestSmsFailureAction
  | RequestSmsEraseAction
