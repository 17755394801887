/* eslint-disable multiline-ternary */
import { notification, Select } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import AddButton from '../../components/AddButton'
import Header from '../../components/Header'
import NewModal from '../../components/NewModal'
import SecondHeader from '../../components/SecondHeader'
import SwotBoard, { SwotColumn } from '../../components/SwotBoard'
import { useFetch } from '../../hooks/useFetch'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import {
  Container,
  SwotAddButton,
  SwotModalContainer,
  SwotModalDropColumn,
  SwotModalRow,
  SwotModalTextArea
} from './styles'

interface Options {
  _id: string
  name: string
}

interface SwotCreationPayload {
  name?: string
  items?: {
    description: string
  }
}
const SwotPage: React.FC = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [name, setName] = useState<string>('')

  const { token } = useSelector((state: RootState) => state.signIn)

  const { data } = useFetch<SwotColumn[]>('swots', token, {
    refreshInterval: 30000
  })

  const { data: options } = useFetch<Options[]>('swot/options', token, {})
  const handleSwotCreation = () => {
    api
      .post(
        'swot/create',
        {
          name,
          items: {
            description
          }
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('swots')
          setModalVisible(!isModalVisible)
        } else {
          notification.error({
            message:
              'Algo deu errado ao tentar criar, por favor tente novamente!'
          })
        }
      })
  }

  const { Option } = Select
  return (
    <>
      <Container>
        <Header />
        <SecondHeader
          title="SWOT"
          content={
            <AddButton
              label="Adicionar"
              onClickEvent={() => setModalVisible(true)}
            />
          }
        />
        <SwotBoard fetchedData={data} />
      </Container>
      <NewModal
        title="Adicionar Swot"
        visible={isModalVisible}
        destroyOnClose={true}
        onCancel={() => setModalVisible(!isModalVisible)}
      >
        <SwotModalContainer>
          <SwotModalRow>
            <SwotModalTextArea
              placeholder="Descrição"
              onChange={e => setDescription(e.target.value)}
            />
            <SwotAddButton />
            <SwotModalDropColumn>
              <Select
                placeholder="SWOT"
                onChange={option => setName(option.toString())}
                bordered={false}
                style={{ backgroundColor: '#fafafa' }}
              >
                {options?.map(item => (
                  <Option key={item._id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <div style={{ marginTop: 5, marginBottom: 5 }} />
              <AddButton
                label="Adicionar"
                width="150px"
                height="40px"
                fontSize="14px"
                onClickEvent={() => handleSwotCreation()}
              />
            </SwotModalDropColumn>
          </SwotModalRow>
        </SwotModalContainer>
      </NewModal>
    </>
  )
}

export default SwotPage
