import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Lato';
  src: local('Lato'),
    url(./assets/fonts/Lato/Lato-Regular.ttf) format('ttf');
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

textarea {
  font-family: 'Lato', sans-serif;
}

code {
  font-family: 'Lato', sans-serif;
}

:root {
  --primary: #2a569f;
  --green: #4e9f4b;
  --button-green: #3c8101;
  --white: #ffffff;
  --font: 'Lato', sans-serif;
}

`
export default GlobalStyle
