import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

export const PageContent = styled.div`
  display: flex;
  height: 70%;
  align-items: center;
  justify-content: center;
`

export const BIFrame = styled.iframe``
