import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { BodyContainer, HeaderContainer, TitleText } from './styles'
interface Props {
  title: string
  visible: boolean
  onCancel: () => void
  modalHeaderColor?: string
  destroyOnClose?: boolean
}

const NewModal: React.FC<Props> = ({
  title,
  visible,
  onCancel,
  children,
  modalHeaderColor,
  destroyOnClose
}) => {
  return (
    <Modal
      destroyOnClose={destroyOnClose}
      centered={true}
      visible={visible}
      onCancel={onCancel}
      title={null}
      footer={null}
      bodyStyle={{ padding: 0 }}
      closeIcon={<CloseOutlined size={28} style={{ color: '#fff' }} />}
    >
      <HeaderContainer style={{ backgroundColor: modalHeaderColor }}>
        <TitleText>{title}</TitleText>
      </HeaderContainer>
      <BodyContainer>{children}</BodyContainer>
    </Modal>
  )
}

export default NewModal
