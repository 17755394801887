import produce from 'immer'

import {
  ChangePwdState,
  ChangePwdActionTypes,
  CHANGE_PWD_REQUEST,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_FAILURE,
  CHANGE_PWD_ERASE
} from './types'

const INITIAL_STATE: ChangePwdState = {
  changePwdLoading: null,
  changePwdFail: null
}

export default function changePwd(
  state = INITIAL_STATE,
  action: ChangePwdActionTypes
): ChangePwdState {
  switch (action.type) {
    case CHANGE_PWD_REQUEST:
      return produce(state, draft => {
        draft.changePwdLoading = true
        draft.changePwdFail = INITIAL_STATE.changePwdFail
      })
    case CHANGE_PWD_SUCCESS:
      return produce(state, draft => {
        draft.changePwdLoading = false
        draft.changePwdFail = false
      })
    case CHANGE_PWD_FAILURE:
      return produce(state, draft => {
        draft.changePwdLoading = false
        draft.changePwdFail = true
      })
    case CHANGE_PWD_ERASE:
      return produce(state, draft => {
        draft.changePwdLoading = INITIAL_STATE.changePwdLoading
        draft.changePwdFail = INITIAL_STATE.changePwdFail
      })
    default:
      return state
  }
}
