import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { updateEventFailure, updateEventSuccess } from './actions'
import { getEvents } from '../getEvents/actions'
import { UPDATE_EVENT_REQUEST } from './types'

export function* updateEvent(requestPayload: AnyAction) {
  try {
    const { payload, token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    yield call(api.patch, 'timeline/edit', payload, config)

    yield put(updateEventSuccess())
    yield put(getEvents(token))
  } catch (err) {
    yield put(updateEventFailure())
  }
}

export default all([takeLatest(UPDATE_EVENT_REQUEST, updateEvent)])
