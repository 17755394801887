import 'antd/dist/antd.css'

import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'

import 'react-dropdown/style.css'
import GlobalStyle from './GlobalStyles'
import './overrides.css'
import Routes from './routes/Routes'

import './App.css'

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <Routes />
      </Provider>
    </>
  )
}

export default App
