import React from 'react'

import bkpiLogo from '../../assets/bkpi_logo.png'

import { Container, BkpiLogoContainer, BkpiLogoText, BkpiLogo } from './styles'

const Footer: React.FC = () => {
  return (
    <Container>
      <BkpiLogoContainer>
        <BkpiLogoText>Powered by</BkpiLogoText>
        <BkpiLogo src={bkpiLogo} alt="bkpi" />
      </BkpiLogoContainer>
    </Container>
  )
}

export default Footer
