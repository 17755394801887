import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import logo from '../../assets/logo.png'
import { RootState } from '../../store/rootReducers'
import NavigateButtonsContainer from '../NavigateButtonsContainer'
import NavigatePopover from '../NavigatePopover'
import NotificationPopover from '../NotificationPopover'
import MenuPopover from '../MenuPopover'

import {
  Container,
  LogoImage,
  IconsContainer,
  IconBlock,
  HeaderContent,
  NavigateIconBlock
} from './styles'

const Header: React.FC = () => {
  const { signed } = useSelector((state: RootState) => state.signIn)
  const { userInfo } = useSelector((state: RootState) => state.getUserInfo)

  const renderHeaderContent = (): React.ReactNode => {
    if (signed) {
      return (
        <HeaderContent>
          <NavigateButtonsContainer />
          <IconsContainer>
            <NavigateIconBlock>
              <NavigatePopover />
            </NavigateIconBlock>
            {/* <IconBlock>
              <NotificationPopover />
            </IconBlock> */}
            <IconBlock>
              <MenuPopover />
            </IconBlock>
            <IconBlock style={{ cursor: 'initial' }}>
              <Avatar
                size={58}
                icon={<UserOutlined />}
                src={userInfo && userInfo.image}
              />
            </IconBlock>
          </IconsContainer>
        </HeaderContent>
      )
    }

    return <></>
  }

  return (
    <Container>
      <LogoImage src={logo} alt="logo" />
      {renderHeaderContent()}
    </Container>
  )
}

export default Header
