import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../../services/api'
import { getUserInfo } from '../../user/getUserInfo/actions'
import { signInFailure, signInSuccess } from './actions'
import { SIGN_IN_REQUEST } from './types'

export function* signIn(payload: AnyAction) {
  try {
    const response: AxiosResponse = yield call(
      api.post,
      'login',
      payload.payload
    )

    const { data } = response

    // api.defaults.headers.Authorization = data.token
    localStorage.setItem('token', data.token)
    localStorage.setItem('role', data.role)
    yield put(signInSuccess(data))
    window.location.replace(
      data.role === '6028f919e0e54f136a9b48af' ? '/projectpage' : '/files'
    )
    yield put(getUserInfo(data.token))
  } catch (err) {
    const { errors, message } = err.response.data

    yield put(
      signInFailure({
        message: errors !== undefined ? errors[0].msg : message
      })
    )
  }
}

export default all([takeLatest(SIGN_IN_REQUEST, signIn)])
