import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Popover } from 'antd'
import { FiLogOut, FiEdit } from 'react-icons/fi'
import { IoMdMenu } from 'react-icons/io'

import { signOut } from '../../store/modules/authentication/signIn/actions'
import PopoverItem from '../PopoverItem'

import { MenuTitleText, PopoverContentBlock } from './styles'
import EditProfileModal from '../EditProfileModal'

const MenuPopover: React.FC = () => {
  const dispatch = useDispatch()

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isEditProfileOpen, setIsEditProfileOpen] = useState<boolean>(false)

  const handleSignOutSubmit = (): void => {
    dispatch(signOut())
    setIsVisible(false)
  }

  const renderEditProfileModal = (): React.ReactNode => {
    if (isEditProfileOpen) {
      return (
        <EditProfileModal
          visible={isEditProfileOpen}
          onCancel={() => setIsEditProfileOpen(false)}
        />
      )
    }

    return <></>
  }

  return (
    <>
      <Popover
        title={<MenuTitleText>Perfil</MenuTitleText>}
        visible={isVisible}
        onVisibleChange={visible => setIsVisible(visible)}
        content={
          <PopoverContentBlock>
            <PopoverItem
              label="Editar"
              icon={<FiEdit />}
              onClickEvent={() => {
                setIsEditProfileOpen(true)
                setIsVisible(false)
              }}
            />
            <PopoverItem
              label="Log out"
              icon={<FiLogOut />}
              onClickEvent={handleSignOutSubmit}
            />
          </PopoverContentBlock>
        }
      >
        <IoMdMenu size={30} />
      </Popover>
      {renderEditProfileModal()}
    </>
  )
}

export default MenuPopover
