/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Button, Input, notification, Popover, Select } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import lodash from 'lodash'
import moment, { Moment } from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useRef, useState } from 'react'
import { AiFillPlusCircle, AiOutlineClose } from 'react-icons/ai'
import { BiAddToQueue } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import ActionsPopover from '../../components/ActionsPopover'
import Activity from '../../components/Activity'
import AddButton from '../../components/AddButton'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import NewModal from '../../components/NewModal'
import ProjectBoard, { fResponse } from '../../components/ProjectBoard'
import SecondHeader from '../../components/SecondHeader'
import Tag from '../../components/Tag'
import { useFetch } from '../../hooks/useFetch'
import '../../overrides.css'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import {
  ActionsBlock,
  ActivitesBox,
  ActivitiesInnerContainer,
  ActivitiesTitle,
  Container,
  CreateTagContainer,
  PageContainer,
  ProjectModalContainer,
  ProjectModalDescriptionContainer,
  ProjectModalRow,
  ProjectModalSelectionColumn,
  ProjectModalTextArea,
  ProjectModalTextHeader,
  ProjectTableContainer,
  RightSideContainer,
  SecondHeaderContentBlock,
  TagDivider,
  TagListContainer,
  TagListInnerContainer
} from './styles'

interface CalendarEvents {
  endDate: string
  _id: string
}

interface FormattedCalendarEvents {
  date: string
  title: string
  color: string
}

export interface Tags {
  _id: string
  title: string
  color: string
}

interface TagPayload {
  title?: string
  color?: string
}
export interface OwnerOption {
  _id: string
  name: string
}
export interface ExecutedBy {
  _id: string
  name: string
}

export interface Activity {
  _id: string
  executedBy: ExecutedBy
  action: 'create' | 'edit' | 'delete'
  modelEntity: string
  modelEntityName: string
  createdAt: string
  updatedAt: Date
}

const ProjectPage: React.FC = () => {
  const [createBoardModal, setCreateBoardModal] = useState<boolean>(false)

  const [formattedCalendarEvents, setCalendarEvents] = useState<
    FormattedCalendarEvents[]
  >([])

  const [newBoardName, setNewBoardName] = useState<string>('')
  const [color, setColor] = useState<string>('')

  const fullCalendarRef = useRef<FullCalendar | null>(null)

  const [filterMe, setFilterMe] = useState<boolean>(false)
  const [filterDate, setFilterDate] = useState<string | undefined>('')
  const [filterTags, setFilterTags] = useState<Tags[]>([])

  const [pickerVisible, setVisiblePicker] = useState<boolean>(false)

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [fTagVisible, setFTagVisible] = useState<boolean>(false)
  const [createTag, setCreateTag] = useState<TagPayload>()

  const [description, setDescription] = useState<string>('')
  const [limitDate, setLimitDate] = useState<string>('')
  const [owner, setOwner] = useState<string>('')
  const [board, setBoard] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [editMode, setEditMode] = useState<boolean>(true)

  const [selectedTags, setTags] = useState<Tags[]>([])
  const { token } = useSelector((state: RootState) => state.signIn)
  const projectStr = `project/board?me=${filterMe}&date=${filterDate}&tags=${filterTags
    .map(item => item._id)
    .join()}`

  const { data: fetchedData } = useFetch<fResponse[]>(projectStr, token, {
    refreshInterval: 30000
  })

  const { data: activities } = useFetch<Activity[]>('audit', token, {})
  const { data: tags } = useFetch<Tags[]>('project/tag', token, {})
  const { data: ownerOptions } = useFetch<OwnerOption[]>(
    'project/users',
    token,
    {}
  )
  const { data: calendarEvents } = useFetch<CalendarEvents[]>(
    'project/card/calendar',
    token,
    {}
  )

  const { userInfo } = useSelector((state: RootState) => state.getUserInfo)
  const dateFormat = 'DD/MM/YYYY'
  useEffect(() => {
    if (calendarEvents) {
      const newEvent: FormattedCalendarEvents[] = []
      calendarEvents?.map(item => {
        newEvent.push({
          title: '',
          color: '#3c569f',
          date: item.endDate?.replace('T00:00:00.000Z', '')
        })
      })
      setCalendarEvents(newEvent)
    }
  }, [calendarEvents])

  const openModal = () => setModalVisible(true)

  const { Option } = Select

  function disabledDate(current: Moment) {
    return current && current < moment().endOf('day')
  }

  const handleTagSelection = (item: Tags) => {
    if (selectedTags.includes(item)) {
      const newArray = selectedTags.filter(tag => tag !== item)
      setTags(newArray)
    } else {
      setTags([...selectedTags, item])
    }
  }

  const handleTagCreation = () => {
    if (!createTag?.title && !color) {
      return notification.warn({
        message: 'Por favor preencha os campos corretamente!'
      })
    }

    api
      .post(
        'project/tag/create',
        { color, title: createTag?.title },
        {
          headers: { authorization: token }
        }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('project/tag')
          notification.success({
            message: 'Tag criada com sucesso!'
          })
        } else {
          notification.success({
            message: 'Algo deu errado ao tentar criar uma nova tag!'
          })
        }
      })
  }

  const handleBoardCreation = () => {
    api
      .post(
        'project/board/create',
        {
          title: newBoardName
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          setCreateBoardModal(!createBoardModal)
          mutate(projectStr)
          mutate('audit')

          notification.success({ message: 'Quadro criado com sucesso' })
        } else {
          notification.error({ message: 'Falha ao tentar criar novo quadro' })
        }
      })
  }

  const handleFilterTagSelection = (item: Tags) => {
    if (filterTags.includes(item)) {
      setFilterDate('')
      setFilterMe(false)
      const newArray = filterTags.filter(tag => tag !== item)
      setFilterTags(newArray)
    } else {
      setFilterTags([...filterTags, item])
    }
  }
  const handleCardCreation = () => {
    if (board === '' || description === '') {
      notification.warn({
        message: 'Por favor preencha os campos corretamente!'
      })
    }
    api
      .post(
        'project/card/create',
        {
          board,
          title,
          owner: owner !== '' ? owner : userInfo?._id,
          description,
          endDate: limitDate || '' ? limitDate : '',
          tags: selectedTags
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          setFilterDate('')
          setFilterMe(false)
          mutate(projectStr).then(() => {
            mutate('project/card/calendar')
            mutate('audit')
            notification.success({ message: 'Card criado com sucesso!' })
            setBoard('')
            setTitle('')
            setOwner('')
            setDescription('')
            setTags([])
            setLimitDate('')
            setModalVisible(!isModalVisible)
          })
        } else {
          notification.error({
            message: 'Ocorreu um arro ao tentar criar um novo card!'
          })
        }
      })
  }

  const handleTagDelete = (_id: string) => {
    api
      .post(
        'project/tag/delete',
        {
          _id
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('project/tag').then(() => {
            notification.success({ message: 'Tag deletada com sucesso!' })
          })
          mutate(projectStr)
          mutate('audit')
        } else {
          notification.error({ message: 'Não foi possível deletar essa tag!' })
        }
      })
  }
  const TagsComponent = () => (
    <TagListContainer>
      <TagListInnerContainer>
        <CreateTagContainer>
          <Input
            size="middle"
            placeholder="Nova tag"
            onFocus={() => setVisiblePicker(!pickerVisible)}
            style={{ borderRadius: 25 }}
            onChange={e =>
              setCreateTag({ title: e.target.value, color: createTag?.color })
            }
            suffix={
              <>
                <input
                  type="color"
                  style={{
                    border: 'none',
                    width: '100%',
                    outline: '0',
                    backgroundColor: 'transparent',
                    height: 20,
                    borderRadius: 5
                  }}
                  value={color}
                  onChange={e => setColor(e.target.value)}
                  // onChange={e =>
                  //   setCreateTag({
                  //     title: createTag?.title,
                  //     color: e.target.value
                  //   })
                  // }
                />
                <BiAddToQueue
                  size={25}
                  cursor="pointer"
                  color="#000"
                  onClick={() => handleTagCreation()}
                />
              </>
            }
          />
          <AiOutlineClose
            style={{ cursor: 'pointer' }}
            size={25}
            color="#000"
            onClick={() => setVisible(false)}
          />
          <Checkbox onChange={() => setEditMode(!editMode)} value={editMode} />
        </CreateTagContainer>

        {tags?.map((item, index) => (
          <>
            <TagDivider
              key={item._id}
              onClick={() => (!editMode ? null : handleTagSelection(item))}
            >
              <Tag
                card={false}
                selected={!editMode ? true : selectedTags?.includes(item)}
                content={item.title}
                backgroundColor={item.color}
                width={100}
                height={30}
                key={index}
                editMode={!editMode}
                editModeCallback={() => handleTagDelete(item._id)}
              />
            </TagDivider>
          </>
        ))}
      </TagListInnerContainer>
    </TagListContainer>
  )

  if (!fetchedData && !activities) {
    return <LoadingScreen />
  }
  return (
    <PageContainer>
      <Header />
      <SecondHeader
        title="Projetos"
        content={
          <SecondHeaderContentBlock>
            <ActionsPopover
              myProjectsClickEvent={() => console.log('TODO')}
              tagsClickEvent={() => console.log('TODO')}
            />
            <ActionsBlock>
              <span
                onClick={() => {
                  setFilterMe(!filterMe)
                  setFilterDate('')
                }}
                style={{
                  color: '#ffff',
                  fontWeight: 'bold',
                  fontSize: 18,
                  cursor: 'pointer'
                }}
              >
                Meus projetos
              </span>
              <span
                onClick={() => setFTagVisible(!fTagVisible)}
                style={{
                  color: 'white',
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginLeft: 10
                }}
              >
                Tags
              </span>
              <Popover
                visible={fTagVisible}
                content={() => {
                  return (
                    <div>
                      {tags?.map((item, index) => (
                        <div
                          key={item._id}
                          onClick={() => handleFilterTagSelection(item)}
                        >
                          <Tag
                            selected={filterTags.includes(item)}
                            content={item.title}
                            backgroundColor={item.color}
                            width={100}
                            height={30}
                            key={index}
                          />
                        </div>
                      ))}
                    </div>
                  )
                }}
              />
            </ActionsBlock>
            <AddButton onClickEvent={openModal} label="Adicionar" />
          </SecondHeaderContentBlock>
        }
      ></SecondHeader>
      <Container>
        <ProjectTableContainer>
          <div>
            <AiFillPlusCircle
              color="#d5d5d5"
              size={40}
              style={{ marginTop: 20 }}
              cursor="pointer"
              onClick={() => setCreateBoardModal(!createBoardModal)}
            />
          </div>
          <ProjectBoard mutateStr={projectStr} fetchedData={fetchedData} />
        </ProjectTableContainer>

        <RightSideContainer>
          <div style={{ maxWidth: '25vw' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'var(--primary)',
                width: '100%',
                padding: 10,
                marginBottom: -20
              }}
            >
              <span
                style={{
                  textTransform: 'capitalize',
                  fontFamily: 'Lato, sans-serif',
                  color: 'white',
                  fontWeight: 'bold'
                }}
              >
                {
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.currentDataManager?.getCurrentData().viewTitle
                }
              </span>
            </div>
            <FullCalendar
              ref={fullCalendarRef}
              eventDisplay="a"
              plugins={[dayGridPlugin]}
              dayMaxEventRows={false}
              initialView="dayGridMonth"
              editable={false}
              selectable={false}
              selectMirror={false}
              weekends={true}
              headerToolbar={{
                center: '',
                left: '',
                right: '',
                start: ''
              }}
              dayMaxEvents={false}
              height={350}
              aspectRatio={50}
              locale="pt-br"
              eventContent={eventInfo => (
                <>
                  <div
                    style={{
                      fontFamily: 'Lato, sans-serif',
                      color: 'white',
                      fontWeight: 'bold',
                      height: 10
                    }}
                  >
                    {eventInfo.event.title}
                  </div>
                </>
              )}
              eventClick={e => {
                if (filterDate === moment(e.event.start).format('YYYY-MM-DD')) {
                  setFilterDate('')
                } else {
                  setFilterMe(false)
                  setFilterDate(moment(e.event.start).format('YYYY-MM-DD'))
                }
              }}
              events={lodash.uniqBy(formattedCalendarEvents, 'date')}
            />
          </div>

          <ActivitesBox>
            <ActivitiesTitle>Atividades</ActivitiesTitle>
            <ActivitiesInnerContainer>
              {activities?.map(item => (
                <Activity
                  key={item._id}
                  modelEntityName={item.modelEntityName}
                  executedBy={item?.executedBy?.name}
                  createdAt={item.createdAt}
                  modelEntity={item.modelEntity}
                  action={item.action}
                />
              ))}
            </ActivitiesInnerContainer>
          </ActivitesBox>
        </RightSideContainer>
      </Container>
      <NewModal
        title="Adicionar Projeto"
        destroyOnClose={true}
        onCancel={() => {
          setVisible(false)
          setTimeout(() => {
            setModalVisible(!isModalVisible)
          }, 100)
          setTags([])
        }}
        visible={isModalVisible}
      >
        <ProjectModalContainer style={{ padding: 0 }}>
          <ProjectModalRow>
            <ProjectModalDescriptionContainer>
              <ProjectModalTextHeader>
                <Input
                  placeholder="Título"
                  onChange={e => setTitle(e.target.value)}
                  bordered={false}
                  style={{
                    color: 'white',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    outline: 0,
                    backgroundColor: 'transparent'
                  }}
                />
              </ProjectModalTextHeader>
              <ProjectModalTextArea
                onChange={e => setDescription(e.target.value)}
              />
            </ProjectModalDescriptionContainer>

            <ProjectModalSelectionColumn>
              <AddButton
                label="Adicionar"
                onClickEvent={() => handleCardCreation()}
                height="40px"
              />
            </ProjectModalSelectionColumn>
          </ProjectModalRow>
        </ProjectModalContainer>
      </NewModal>

      <NewModal
        destroyOnClose={true}
        visible={createBoardModal}
        onCancel={() => setCreateBoardModal(!createBoardModal)}
        title="Adicionar Quadro"
      >
        <div style={{ flexDirection: 'row', display: 'flex ' }}>
          <Input
            onChange={e => setNewBoardName(e.target.value)}
            bordered={false}
            style={{ backgroundColor: '#fafafa' }}
            placeholder="Novo quadro"
          />
          <Button
            style={{
              backgroundColor: '#57bb00',
              border: 'none',
              outline: 0,
              color: 'white',
              marginLeft: 5
            }}
            loading={loading}
            onClick={() => handleBoardCreation()}
          >
            Adicionar
          </Button>
        </div>
      </NewModal>
    </PageContainer>
  )
}
export default ProjectPage
