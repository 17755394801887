import styled from 'styled-components'

export const AddButtonContainer = styled.div``

export const AddButtonBlock = styled.div`
  background-color: var(--button-green);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.5s;
  font-size: 18px;

  cursor: pointer;

  &:hover {
    background-color: var(--green);
  }
`

export const AddButtonBlockDisabled = styled.div`
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.5s;
  font-size: 18px;

  cursor: not-allowed;
`

export const AddButtonText = styled.span`
  color: white;
  text-align: center;
  margin-left: 8px;
  letter-spacing: 1.2px;
  font-weight: bold;
  text-transform: uppercase;
`
