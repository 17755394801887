import produce from 'immer'

import {
  DeleteEventActionTypes,
  DeleteEventState,
  DELETE_EVENT_ERASE,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS
} from './types'

const INITIAL_STATE: DeleteEventState = {
  deleteEventLoading: null,
  deleteEventFail: null
}

export default function deleteEvent(
  state = INITIAL_STATE,
  action: DeleteEventActionTypes
): DeleteEventState {
  switch (action.type) {
    case DELETE_EVENT_REQUEST:
      return produce(state, draft => {
        draft.deleteEventLoading = true
        draft.deleteEventFail = INITIAL_STATE.deleteEventFail
      })
    case DELETE_EVENT_SUCCESS:
      return produce(state, draft => {
        draft.deleteEventLoading = false
        draft.deleteEventFail = false
      })
    case DELETE_EVENT_FAILURE:
      return produce(state, draft => {
        draft.deleteEventLoading = false
        draft.deleteEventFail = true
      })
    case DELETE_EVENT_ERASE:
      return produce(state, draft => {
        draft.deleteEventLoading = INITIAL_STATE.deleteEventLoading
        draft.deleteEventFail = INITIAL_STATE.deleteEventFail
      })
    default:
      return state
  }
}
