import produce from 'immer'

import {
  GetEventsActionTypes,
  GetEventsState,
  GET_EVENTS_ERASE,
  GET_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS
} from './types'

const INITIAL_STATE: GetEventsState = {
  getEventsLoading: null,
  getEventsEmpty: null,
  getEventsFail: null,
  eventsData: null
}

export default function getEvents(
  state = INITIAL_STATE,
  action: GetEventsActionTypes
): GetEventsState {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return produce(state, draft => {
        draft.getEventsLoading = true
        draft.getEventsFail = INITIAL_STATE.getEventsFail
        draft.getEventsEmpty = INITIAL_STATE.getEventsEmpty
        draft.eventsData = INITIAL_STATE.eventsData
      })
    case GET_EVENTS_SUCCESS:
      return produce(state, draft => {
        draft.getEventsLoading = false
        draft.getEventsFail = false
        if (action.response.data.length !== 0) {
          draft.getEventsEmpty = false
          draft.eventsData = action.response.data
        } else {
          draft.getEventsEmpty = true
        }
      })
    case GET_EVENTS_FAILURE:
      return produce(state, draft => {
        draft.getEventsLoading = false
        draft.getEventsFail = true
      })
    case GET_EVENTS_ERASE:
      return produce(state, draft => {
        draft.getEventsLoading = INITIAL_STATE.getEventsLoading
        draft.getEventsFail = INITIAL_STATE.getEventsFail
        draft.getEventsEmpty = INITIAL_STATE.getEventsEmpty
        draft.eventsData = INITIAL_STATE.eventsData
      })
    default:
      return state
  }
}
