import produce from 'immer'

import {
  PostPhotoActionTypes,
  PostPhotoState,
  POST_PHOTO_ERASE,
  POST_PHOTO_FAILURE,
  POST_PHOTO_REQUEST,
  POST_PHOTO_SUCCESS
} from './types'

const INITIAL_STATE: PostPhotoState = {
  postPhotoLoading: null,
  postPhotoFail: null
}

export default function postPhoto(
  state = INITIAL_STATE,
  action: PostPhotoActionTypes
): PostPhotoState {
  switch (action.type) {
    case POST_PHOTO_REQUEST:
      return produce(state, draft => {
        draft.postPhotoLoading = true
        draft.postPhotoFail = INITIAL_STATE.postPhotoFail
      })
    case POST_PHOTO_SUCCESS:
      return produce(state, draft => {
        draft.postPhotoLoading = false
        draft.postPhotoFail = false
      })
    case POST_PHOTO_FAILURE:
      return produce(state, draft => {
        draft.postPhotoLoading = false
        draft.postPhotoFail = true
      })
    case POST_PHOTO_ERASE:
      return produce(state, draft => {
        draft.postPhotoLoading = INITIAL_STATE.postPhotoLoading
        draft.postPhotoFail = INITIAL_STATE.postPhotoFail
      })
    default:
      return state
  }
}
