import { notification } from 'antd'
import React, { useRef, useState } from 'react'
import { IoIosFolderOpen } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { postPhoto } from '../../store/modules/gallery/postPhoto/actions'
import { RootState } from '../../store/rootReducers'
import AddButton from '../AddButton'
import NewModal from '../NewModal'
import {
  AddButtonBlock,
  FileInput,
  ModalContainer,
  Placeholder,
  Value
} from './styles'

interface Props {
  visible: boolean
  onCancel: () => void
}

const UploadPhotoModal: React.FC<Props> = ({ visible, onCancel }) => {
  const dispatch = useDispatch()

  const [photo, setPhoto] = useState<File | null>(null)

  const { postPhotoLoading } = useSelector(
    (state: RootState) => state.postPhoto
  )
  const { token } = useSelector((state: RootState) => state.signIn)

  const fileInput = useRef<HTMLInputElement>(null)

  const renderImageInput = () => fileInput.current?.click()

  const isNotAllowedToUploadPhoto: boolean =
    photo === null || postPhotoLoading === true

  const fileFailValidation = (file: File) => {
    if (!file.type.includes('image') || file.size / 1024 / 1024 > 2) {
      return true
    }
    return false
  }

  const fileChangedHandler = (event: any): void => {
    if (!event.target.files[0]) return

    if (fileFailValidation(event.target.files[0])) {
      notification.config({ duration: 3 })
      notification.warning({
        message:
          'O arquivo selecionado precisa ser uma imagem valida no formato Jpg ou Png de até 2MB'
      })
      return
    }

    setPhoto(event.target.files[0])
  }

  const handleSubmitPostPhoto = (): void => {
    if (!postPhotoLoading && token && photo) {
      const dataForm = new FormData()
      dataForm.append('file', photo)

      dispatch(postPhoto(dataForm, token))
      onCancel()
    }
  }

  return (
    <NewModal visible={visible} onCancel={onCancel} title="Adicionar Foto">
      <ModalContainer>
        <FileInput onClick={renderImageInput}>
          <IoIosFolderOpen size={22} style={{ marginRight: 10 }} />
          {photo ? (
            <Value>{photo.name}</Value>
          ) : (
            <Placeholder>Procurar no dispositivo</Placeholder>
          )}
        </FileInput>
        <input
          onChange={fileChangedHandler}
          style={{ display: 'none' }}
          type="file"
          id="img"
          name="img"
          accept="image/*"
          ref={fileInput}
        />
        <AddButtonBlock>
          <AddButton
            label="Salvar"
            onClickEvent={handleSubmitPostPhoto}
            disabled={isNotAllowedToUploadPhoto}
          />
        </AddButtonBlock>
      </ModalContainer>
    </NewModal>
  )
}

export default UploadPhotoModal
