import {
  UpdateEventActionTypes,
  UpdateEventPayload,
  UPDATE_EVENT_ERASE,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS
} from './types'

export function updateEvent(
  payload: UpdateEventPayload,
  token: string
): UpdateEventActionTypes {
  return {
    type: UPDATE_EVENT_REQUEST,
    payload,
    token
  }
}

export function updateEventSuccess(): UpdateEventActionTypes {
  return {
    type: UPDATE_EVENT_SUCCESS
  }
}

export function updateEventFailure(): UpdateEventActionTypes {
  return {
    type: UPDATE_EVENT_FAILURE
  }
}

export function updateEventErase(): UpdateEventActionTypes {
  return {
    type: UPDATE_EVENT_ERASE
  }
}
