import styled from 'styled-components'

interface SwotColumnHeaderProps {
  color: string
}
export const SwotColumnHeader = styled.div<SwotColumnHeaderProps>`
  background-color: ${props => props.color};
  width: 90%;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

export const SwotColumnTitle = styled.span`
  color: white;
  margin-left: 2.5%;
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
`

export const RowContainer = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  * + & {
    margin-top: 15px;
  }
`

export const RowContent = styled.span`
  font-weight: bold;
  color: '#8888';
  font-size: 15px;
  width: 80%;
`
