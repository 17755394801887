import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

export const BgContainer = styled.div`
  display: flex;
  height: calc(100vh - 148px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('/bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
`

export const FirstText = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-family: var(--font);
`

export const SecondText = styled.span`
  padding: 25px 0 0 0;
  font-size: 58px;
  text-transform: uppercase;
  text-align: center;
  font-family: var(--font);
  color: #fff;

  @media (max-width: 830px) {
    font-size: 48px;
  }

  @media (max-width: 450px) {
    font-size: 38px;
  }
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ThirdText = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 8px;
  font-family: var(--font);

  @media (max-width: 450px) {
    font-size: 16px;
  }
`
