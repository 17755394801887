import {
  PostEventActionTypes,
  PostEventPayload,
  PostEventSuccessResponse,
  POST_EVENT_ERASE,
  POST_EVENT_FAILURE,
  POST_EVENT_REQUEST,
  POST_EVENT_SUCCESS
} from './types'

export function postEvent(
  payload: PostEventPayload,
  token: string
): PostEventActionTypes {
  return {
    type: POST_EVENT_REQUEST,
    payload,
    token
  }
}

export function postEventSuccess(
  response: PostEventSuccessResponse
): PostEventActionTypes {
  return {
    type: POST_EVENT_SUCCESS,
    response
  }
}

export function postEventFailure(): PostEventActionTypes {
  return {
    type: POST_EVENT_FAILURE
  }
}

export function postEventErase(): PostEventActionTypes {
  return {
    type: POST_EVENT_ERASE
  }
}
