import styled from 'styled-components'
import { Input, DatePicker } from 'antd'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const DatePickerInput = styled(DatePicker)`
  margin-bottom: 100%;

  .ant-picker-input {
    font-size: 18px;

    input {
      font-size: 18px;
    }
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`

export const TextAreaInput = styled(Input.TextArea)`
  width: 62%;
  height: 248px !important;
  font-size: 18px !important;

  @media (max-width: 500px) {
    width: auto;
    margin-right: 10px;
  }
`
