import produce from 'immer'

import {
  PostEventActionTypes,
  PostEventState,
  POST_EVENT_ERASE,
  POST_EVENT_FAILURE,
  POST_EVENT_REQUEST,
  POST_EVENT_SUCCESS
} from './types'

const INITIAL_STATE: PostEventState = {
  postEventLoading: null,
  postEventFail: null
}

export default function postEvent(
  state = INITIAL_STATE,
  action: PostEventActionTypes
): PostEventState {
  switch (action.type) {
    case POST_EVENT_REQUEST:
      return produce(state, draft => {
        draft.postEventLoading = true
        draft.postEventFail = INITIAL_STATE.postEventFail
      })
    case POST_EVENT_SUCCESS:
      return produce(state, draft => {
        draft.postEventLoading = false
        draft.postEventFail = false
      })
    case POST_EVENT_FAILURE:
      return produce(state, draft => {
        draft.postEventLoading = false
        draft.postEventFail = true
      })
    case POST_EVENT_ERASE:
      return produce(state, draft => {
        draft.postEventLoading = INITIAL_STATE.postEventLoading
        draft.postEventFail = INITIAL_STATE.postEventFail
      })
    default:
      return state
  }
}
