import {
  CheckCodeActionTypes,
  CheckCodeFailureResponse,
  CHECK_CODE_ERASE,
  CHECK_CODE_FAILURE,
  CHECK_CODE_REQUEST,
  CHECK_CODE_SUCCESS
} from './types'

export function checkCode(code: string, token: string): CheckCodeActionTypes {
  return {
    type: CHECK_CODE_REQUEST,
    code,
    token
  }
}

export function checkCodeSuccess(): CheckCodeActionTypes {
  return {
    type: CHECK_CODE_SUCCESS
  }
}

export function checkCodeFailure(
  response: CheckCodeFailureResponse
): CheckCodeActionTypes {
  return {
    type: CHECK_CODE_FAILURE,
    response
  }
}

export function checkCodeErase(): CheckCodeActionTypes {
  return {
    type: CHECK_CODE_ERASE
  }
}
