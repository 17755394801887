import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
`

export const TextBlock = styled.div`
  text-align: center;
`

export const FormContainer = styled.div`
  margin-top: 20px;
`

export const Error = styled.div`
  margin-bottom: 20px;
  text-align: center;

  span {
    color: #c53030;
    font-weight: 500;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
