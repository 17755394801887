import produce from 'immer'
import {
  GetPhotosActionTypes,
  GetPhotosState,
  GET_PHOTOS_ERASE,
  GET_PHOTOS_FAILURE,
  GET_PHOTOS_REQUEST,
  GET_PHOTOS_SUCCESS
} from './types'

const INITIAL_STATE: GetPhotosState = {
  getPhotosLoading: null,
  getPhotosFail: null,
  photos: null
}

export default function getPhotos(
  state = INITIAL_STATE,
  action: GetPhotosActionTypes
): GetPhotosState {
  switch (action.type) {
    case GET_PHOTOS_REQUEST:
      return produce(state, draft => {
        draft.getPhotosLoading = true
        draft.getPhotosFail = INITIAL_STATE.getPhotosFail
        draft.photos = INITIAL_STATE.photos
      })
    case GET_PHOTOS_SUCCESS:
      return produce(state, draft => {
        draft.getPhotosLoading = false
        draft.getPhotosFail = false
        draft.photos = action.response
      })
    case GET_PHOTOS_FAILURE:
      return produce(state, draft => {
        draft.getPhotosLoading = false
        draft.getPhotosFail = true
      })
    case GET_PHOTOS_ERASE:
      return produce(state, draft => {
        draft.getPhotosLoading = INITIAL_STATE.getPhotosLoading
        draft.getPhotosFail = INITIAL_STATE.getPhotosFail
        draft.photos = INITIAL_STATE.photos
      })
    default:
      return state
  }
}
