/* eslint-disable indent */
import React from 'react'
import { checkRole } from '../../utils/checkRole'
import NavigateButton from '../NavigateButton'
import { NavigateButtonsBlock } from './styles'

const NavigateButtonsContainer: React.FC = () => {
  const adminRoutes = [
    {
      label: 'Projetos',
      navigateTo: '/projectpage'
    },
    {
      label: 'Swot',
      navigateTo: '/swotpage'
    },
    {
      label: 'BI',
      navigateTo: '/bi'
    },
    {
      label: 'Timeline',
      navigateTo: '/timeline'
    },
    {
      label: 'Fotos',
      navigateTo: '/gallery'
    },
    {
      label: 'Documentos',
      navigateTo: '/files'
    },
    {
      label: 'Usuários',
      navigateTo: '/newuser'
    }
  ]

  const userRoutes = [
    {
      label: 'Documentos',
      navigateTo: '/files'
    }
  ]

  return (
    <NavigateButtonsBlock>
      {checkRole()
        ? adminRoutes.map(item => (
            <NavigateButton
              key={item.label}
              label={item.label}
              navigateTo={item.navigateTo}
            />
          ))
        : userRoutes.map(item => (
            <NavigateButton
              key={item.label}
              label={item.label}
              navigateTo={item.navigateTo}
            />
          ))}
    </NavigateButtonsBlock>
  )
}

export default NavigateButtonsContainer
