/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
import { Button, Input, notification, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import NewModal from '../../components/NewModal'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import {
  RowContainer,
  RowContent,
  SwotColumnHeader,
  SwotColumnTitle
} from './styles'

export interface SwotItem {
  _id: string
  description: string | undefined
}

export interface SwotColumn {
  _id: string
  name: string
  color: string
  index: number
  items: SwotItem[]
  createdAt: string
}

interface EditingItem {
  row: SwotItem
  column: SwotColumn
}

interface UpdatedData {
  name: string | undefined
  items: {
    _id: string | any
    description: string | undefined
  }
}
type Props = {
  fetchedData: SwotColumn[] | undefined
}
const SwotBoard: React.FC<Props> = ({ fetchedData }) => {
  const [columns, setColumns] = useState<SwotColumn[] | null>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const [editingItem, setEditingItem] = useState<EditingItem>()
  const [updatedData, setUpdatedData] = useState<UpdatedData>()

  const [updatedDescription, setNewDescription] = useState<string | undefined>(
    ''
  )

  const { token } = useSelector((state: RootState) => state.signIn)
  const handleItemUpdate = () => {
    api
      .patch(
        'swot/edit',
        {
          name: updatedData?.name,
          items: {
            _id: editingItem?.row._id,
            description: updatedDescription
          }
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('swots')

          notification.success({ message: 'Item editado com sucesso!' })
        } else {
          notification.error({
            message:
              'Algo deu errado ao tentar editar o item, por favor tente novamente!'
          })
        }
        setModalVisible(!modalVisible)
      })
  }

  const handleItemDelete = (item: SwotColumn, row: SwotItem) => {
    api
      .delete('swot/delete', {
        data: {
          name: item.name,
          items: {
            _id: row._id
          }
        },
        headers: {
          authorization: token
        }
      })
      .then(res => {
        if (res.status === 200) {
          mutate('swots')
          notification.success({ message: 'Item deletado com sucesso!' })
        } else {
          notification.error({
            message:
              'Algo deu errado ao tentar deletar o item, por favor tente novamente!'
          })
        }
      })
  }
  const onDragEnd = (result: DropResult, columns: SwotColumn[] | any) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns?.filter(
        (item: SwotColumn) => item._id === source.droppableId
      )[0]

      const destColumn = columns?.filter(
        (item: SwotColumn) => item._id === destination.droppableId
      )[0]

      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)

      if (
        [
          { ...sourceColumn, items: sourceItems },
          { ...destColumn, items: destItems }
        ].includes(undefined)
      ) {
        return
      }

      sourceColumn.items = sourceItems
      destColumn.items = destItems

      api
        .patch(
          'swot/move',
          {
            name: sourceColumn.name,
            items: {
              _id: result.draggableId
            },
            for: destColumn.name
          },
          { headers: { authorization: token } }
        )
        .then(res => {
          if (res.status === 200) {
            notification.success({ message: 'Item movido com sucesso!' })
          } else {
            notification.error({
              message: 'Algo deu errado ao tentar mover item'
            })
          }
        })
    } else {
      const column = columns.filter(
        (item: SwotColumn) => item._id === source.droppableId
      )[0]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)

      column.items = copiedItems
    }
  }
  useEffect(() => {
    if (fetchedData) {
      setColumns(fetchedData)
    }
  }, [fetchedData])

  if (!fetchedData && !columns) {
    return <span>Carregando...</span>
  } else {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          columnCount: 2,
          padding: 10
        }}
      >
        <DragDropContext
          onDragEnd={result => {
            onDragEnd(result, columns)
          }}
        >
          {fetchedData?.map((item: SwotColumn) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  height: 400,
                  marginLeft: -20,
                  marginRight: -20
                }}
                key={item.name}
              >
                <SwotColumnHeader color={item.color}>
                  <SwotColumnTitle>{item.name}</SwotColumnTitle>
                </SwotColumnHeader>
                <Droppable droppableId={item._id} key={item._id}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided?.droppableProps}
                        ref={provided?.innerRef}
                        style={{
                          background: snapshot?.isDraggingOver
                            ? 'lightblue'
                            : 'white',
                          padding: 10,
                          width: '90%',
                          display: 'flex',
                          borderRadius: 8,
                          maxHeight: 300,
                          overflowY: 'auto',
                          alignContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          boxShadow: '0px 3px 6px #00000029'
                        }}
                      >
                        {item.items.map((row: SwotItem, index: number) => {
                          return (
                            <Draggable
                              key={row._id}
                              draggableId={row._id}
                              index={index}
                            >
                              {provided => {
                                return (
                                  <RowContainer
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <RowContent>{row.description}</RowContent>
                                    <div>
                                      <BiPencil
                                        style={{ marginRight: 10 }}
                                        size={20}
                                        cursor="pointer"
                                        onClick={() => {
                                          setEditingItem({
                                            column: item,
                                            row
                                          })
                                          setNewDescription(row.description)
                                          setUpdatedData({
                                            name: item.name,
                                            items: {
                                              _id: row._id,
                                              description: row.description
                                            }
                                          })
                                          setModalVisible(!modalVisible)
                                        }}
                                      />
                                      <BiTrash
                                        size={20}
                                        cursor="pointer"
                                        onClick={() => {
                                          handleItemDelete(item, row)
                                        }}
                                      />
                                    </div>
                                  </RowContainer>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </div>
            )
          })}
        </DragDropContext>
        <NewModal
          modalHeaderColor={editingItem?.column.color}
          title={`Editar ${editingItem?.column.name}: ${editingItem?.row.description}`}
          visible={modalVisible}
          onCancel={() => setModalVisible(!modalVisible)}
        >
          <div style={{ padding: 10, display: 'flex' }}>
            <Input
              type="text"
              onChange={e => setNewDescription(e.target.value)}
              placeholder={editingItem?.row.description}
              value={updatedDescription}
            />
            <Popconfirm
              title="Você realmente deseja atualizar?"
              onConfirm={() => {
                handleItemUpdate()
              }}
              okText="Atualizar"
              cancelText="Cancelar"
              okButtonProps={{
                style: {
                  backgroundColor: '#69c52f',
                  outline: 0,
                  border: 'none'
                }
              }}
              cancelButtonProps={{
                style: {
                  backgroundColor: 'red',
                  color: 'white',
                  fontWeight: 'bold',
                  fontFamily: 'Lato, sans-serif',
                  outline: 0,
                  border: 'none'
                }
              }}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: editingItem?.column.color,
                  border: 'none',
                  outline: 'none',
                  marginLeft: 10
                }}
              >
                Atualizar
              </Button>
            </Popconfirm>
          </div>
        </NewModal>
      </div>
    )
  }
}

export default SwotBoard
