import { Button, Input, notification, Popconfirm, Select, Table } from 'antd'
import React, { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import AddButton from '../../components/AddButton'
import {
  RightContainer,
  TextAreaInput
} from '../../components/EditEventModal/styles'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import NewModal from '../../components/NewModal'
import SecondHeader from '../../components/SecondHeader'
import { useFetch } from '../../hooks/useFetch'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import { checkRole } from '../../utils/checkRole'
import phoneMask from '../../utils/phoneMask'
import { OwnerOption } from '../ProjectPage'
import { Container } from './styles'

interface TableData extends OwnerOption {
  email: string
  cellPhone: string
  role: {
    _id: string
    name: string
  }
}

const CreateUserPage: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [role, setRole] = useState<string>('')

  const { token } = useSelector((state: RootState) => state.signIn)
  const { data } = useFetch<TableData[]>('project/users', token)
  const { data: roles } = useFetch<{ _id: string; name: string }[]>(
    'role/all/createU',
    token
  )
  const { Option } = Select

  const handleCreate = () => {
    if (password.length < 8) {
      return notification.warn({
        message: 'A senha deve ter no mínimo 8 caracteres'
      })
    }
    api
      .post(
        'user/create',
        {
          email,
          password,
          role,
          cellphone:
            '+55' +
            phone
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .replace(/\s/g, ''),
          name
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('project/users').then(() => {
            setVisible(false)
            notification.success({ message: 'Usuário cadastrado com sucesso!' })
          })
        } else {
          notification.warn({
            message: 'Houve um erro ao tentar criar usuário!'
          })
        }
      })
  }

  const handleDelete = (id: string) => {
    api
      .delete('user/delete', {
        data: { _id: id },
        headers: { authorization: token }
      })
      .then(res => {
        if (res.status === 200) {
          mutate('project/users').then(() => {
            notification.success({ message: 'Usuário deletado com sucesso!' })
          })
        } else {
          notification.error({
            message: 'Houve um erro ao tentar deletar o usuário!'
          })
        }
      })
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name'
    },
    {
      title: 'Cargo',
      dataIndex: 'role',
      render: ({ name }: { _id: string; name: string }) => (
        <span>{name.toUpperCase()}</span>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },

    {
      title: 'Telefone',
      dataIndex: 'cellphone',
      render: (value: string) => (
        <span>{phoneMask(value.replace('55', ''))}</span>
      )
    },
    {
      title: 'Deletar',
      render: (item: any) => {
        return (
          <Popconfirm
            title={`Você realmente deseja excluir o usuário ${item.name}?`}
            onConfirm={() => handleDelete(item._id)}
          >
            <FaTrash cursor="pointer" />
          </Popconfirm>
        )
      }
    }
  ]

  if (!data) {
    return <LoadingScreen />
  }
  return (
    <Container>
      <Header />
      <SecondHeader
        title="Repositório"
        content={
          checkRole() ? (
            <AddButton
              label="Adicionar"
              onClickEvent={() => setVisible(true)}
            />
          ) : null
        }
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 24
        }}
      >
        <Table columns={columns} dataSource={data} />
      </div>

      <NewModal
        title="Criar novo usuário"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <Container>
          <Input
            placeholder="Nome"
            onChange={e => setName(e.target.value)}
            style={{ margin: 5 }}
          />
          <Input
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}
            style={{ margin: 5 }}
          />
          <Input
            placeholder="Senha"
            type="password"
            min={9}
            onChange={e => setPassword(e.target.value)}
            style={{ margin: 5 }}
          />
          <Input
            value={phone}
            placeholder="Telefone"
            onChange={e => setPhone(phoneMask(e.target.value))}
            style={{ margin: 5 }}
          />
          <Select
            placeholder="Selecionar Cargo"
            style={{ width: '100%', margin: 5 }}
            onSelect={value => setRole(value.toString())}
          >
            {roles?.map(item => (
              <Option value={item._id} key={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>

          <Button type="primary" size="large" onClick={() => handleCreate()}>
            Criar Usuário
          </Button>
        </Container>
      </NewModal>
    </Container>
  )
}

export default CreateUserPage
