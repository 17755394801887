import React, { useState, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Empty } from 'antd'

import AddButton from '../../components/AddButton'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SecondHeader from '../../components/SecondHeader'
import TimelineCard from '../../components/TimelineCard'
import { RootState } from '../../store/rootReducers'
import { getEvents } from '../../store/modules/events/getEvents/actions'
import LoadingScreen from '../../components/LoadingScreen'
import PostEventModal from '../../components/PostEventModal'

import {
  PageContainer,
  PageContent,
  ContentBlock,
  SliderButton,
  TimelineBlock,
  TimelineScrollView
} from './styles'

const TimelinePage: React.FC = () => {
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const timelineScrollRef = createRef<HTMLDivElement>()

  const { getEventsLoading, eventsData } = useSelector(
    (state: RootState) => state.getEvents
  )
  const { postEventLoading } = useSelector(
    (state: RootState) => state.postEvent
  )
  const { deleteEventLoading } = useSelector(
    (state: RootState) => state.deleteEvent
  )
  const { updateEventLoading } = useSelector(
    (state: RootState) => state.updateEvent
  )
  const { token } = useSelector((state: RootState) => state.signIn)

  const handleToggleModalVisibility = (): void =>
    setIsModalOpen(previousState => !previousState)

  const renderModal = (): React.ReactNode => {
    if (isModalOpen) {
      return (
        <PostEventModal
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      )
    }

    return <></>
  }

  const handleSlidetoLeft = (): void => {
    if (timelineScrollRef.current) timelineScrollRef.current.scrollLeft -= 180
  }

  const handleSlidetoRight = (): void => {
    if (timelineScrollRef.current) timelineScrollRef.current.scrollLeft += 180
  }

  const renderBodyContent = (): React.ReactNode => {
    if (eventsData) {
      return (
        <>
          <SliderButton onClick={handleSlidetoLeft}>
            <AiOutlineLeft />
          </SliderButton>
          <TimelineBlock ref={timelineScrollRef}>
            <TimelineScrollView>
              {eventsData.map((event, index) => (
                <TimelineCard
                  key={index}
                  year={event.year}
                  events={event.events}
                />
              ))}
            </TimelineScrollView>
          </TimelineBlock>
          <SliderButton onClick={handleSlidetoRight}>
            <AiOutlineRight />
          </SliderButton>
        </>
      )
    }

    return <Empty description="Nenhum evento encontrado!" />
  }

  useEffect(() => {
    if (!getEventsLoading && !eventsData && token) {
      dispatch(getEvents(token))
    }
  }, [])

  if (
    getEventsLoading ||
    postEventLoading ||
    updateEventLoading ||
    deleteEventLoading
  ) {
    return <LoadingScreen />
  }

  return (
    <PageContainer>
      <Header />
      <SecondHeader
        title="Timeline"
        content={
          <AddButton
            label="Adicionar"
            onClickEvent={handleToggleModalVisibility}
          />
        }
      />
      <PageContent>
        <ContentBlock>{renderBodyContent()}</ContentBlock>
      </PageContent>
      <Footer />
      {renderModal()}
    </PageContainer>
  )
}

export default TimelinePage
