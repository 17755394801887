import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 148px);
`
