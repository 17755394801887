import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileInput = styled.button`
  border: none;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #afafaf;
  outline: none;

  &:focus {
    outline: none;
  }
`

export const Placeholder = styled.span`
  font-size: 16px;
  font-style: italic;
`

export const Value = styled.span`
  color: #000;
  font-size: 16px;
`

export const AddButtonBlock = styled.div`
  padding-top: 10px;
  width: 140px;
  align-self: flex-end;
`
