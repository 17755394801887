export const UPDATE_EVENT_REQUEST = '@events/UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_SUCCESS = '@events/UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_FAILURE = '@events/UPDATE_EVENT_FAILURE'

export const UPDATE_EVENT_ERASE = '@events/UPDATE_EVENT_ERASE'

export type UpdateEventState = {
  updateEventLoading: boolean | null
  updateEventFail: boolean | null
}

export type UpdateEventPayload = {
  _id: string
  year: string
  description: string
}

export type UpdateEventAction = {
  type: typeof UPDATE_EVENT_REQUEST
  payload: UpdateEventPayload
  token: string
}

export type UpdateEventSuccessAction = {
  type: typeof UPDATE_EVENT_SUCCESS
}

export type UpdateEventFailureAction = {
  type: typeof UPDATE_EVENT_FAILURE
}

export type UpdateEventEraseAction = {
  type: typeof UPDATE_EVENT_ERASE
}

export type UpdateEventActionTypes =
  | UpdateEventAction
  | UpdateEventSuccessAction
  | UpdateEventFailureAction
  | UpdateEventEraseAction
