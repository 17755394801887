import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Input } from 'antd'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

export const BgContainer = styled.div`
  display: flex;
  height: calc(100vh - 148px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('/bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
`

export const TitleText = styled.span`
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;

  @media (max-width: 600px) {
    & {
      font-size: 26px;
    }
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 450px;

  @media (max-width: 520px) {
    & {
      width: 320px;
    }
  }
`

export const LinkText = styled(Link)`
  color: #21b3ff;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 3.2px;
  text-decoration: underline;
  padding-top: 10px;
  padding-left: 20px;
`

export const ErrorBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0 5px 0;
`

export const ErrorText = styled.span`
  color: red;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 3.2px;
  pointer-events: none;
  font-weight: bold;
`

export const BackButton = styled(Link)`
  color: #fff;
  letter-spacing: 12px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-align: center;
  margin-top: 10px;
`

export const SignInEmailInput = styled(Input)`
  height: 60px;
  font-size: 28px;
  border-radius: 30px;

  .ant-input {
    font-size: 22px;
  }

  .ant-input::placeholder {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
  }

  @media (max-width: 520px) {
    .ant-input {
      font-size: 18px;
    }

    .ant-input::placeholder {
      font-size: 18px;
    }
  }
`

export const SignInPwdInput = styled(Input.Password)`
  height: 60px;
  font-size: 28px;
  border-radius: 30px;

  .ant-input {
    font-size: 22px;
  }

  .ant-input::placeholder {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
  }

  @media (max-width: 520px) {
    .ant-input {
      font-size: 18px;
    }

    .ant-input::placeholder {
      font-size: 18px;
    }
  }
`
