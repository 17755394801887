import { Button, Input, notification, Select, Spin } from 'antd'
import React, { useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle
} from 'react-beautiful-dnd'
import { IoIosFolderOpen } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import AddButton from '../../components/AddButton'
import FileAccordion from '../../components/FileAccordion'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import NewModal from '../../components/NewModal'
import SecondHeader from '../../components/SecondHeader'
import {
  FileInput,
  Placeholder
} from '../../components/UploadPhotoModal/styles'
import { useFetch } from '../../hooks/useFetch'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import { checkRole } from '../../utils/checkRole'
import { Container } from './styles'
import { Repository, Role } from './types'
const FileList: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.signIn)
  const { data } = useFetch<Repository[]>('repository/all', token, {})
  const { data: roles } = useFetch<Role[]>('role/all', token, {})
  const [page, setPage] = useState<number>(0)

  const [repositoryTitle, setRepositoryTitle] = useState<string>('')
  const [selectedAccordion, setSelectedAccordion] = useState<number | null>(0)
  const [uploadModal, setUploadModal] = useState<boolean>(false)
  const [title, setFileTitle] = useState<string>('')
  const [repository, setRepository] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [file, setFile] = useState<File | null | any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fileInput = useRef<HTMLInputElement>(null)

  const { Option } = Select

  const handleFileCreate = () => {
    const dataForm = new FormData()

    dataForm.append('file', file)
    dataForm.append('title', title)
    dataForm.append('repository', repository)
    dataForm.append('role', role)

    api
      .post('file/create', dataForm, {
        headers: {
          authorization: token
        }
      })
      .then(() => {
        mutate('repository/all').then(() => {
          setUploadModal(false)
          setFile(null)
          notification.success({ message: 'Arquivo criado com sucesso' })
          setPage(0)
        })
      })
  }

  const handleRepositoryCreation = () => {
    api
      .post(
        'repository/create',
        { title: repositoryTitle },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('repository/all').then(() => {
            setUploadModal(false)
            notification.success({ message: 'Repositório criado com sucesso' })
          })
        }
      })
  }

  const handleRepositoryMove = (
    index: number,
    newIndex: number,
    _id: string
  ) => {
    if (!data) return

    setIsLoading(true)

    api
      .post(
        'repository/move',
        { index, newIndex, _id },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('repository/all').then(() => {
            notification.success({
              message: 'Repositório ordenado com sucesso'
            })
            setIsLoading(false)
          })
        }
      })
  }

  if (!data || !roles) {
    return <LoadingScreen />
  }

  const renderImageInput = () => fileInput.current?.click()
  const selectCreation = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <h3>O que gostaria de inserir?</h3>
        <div>
          <Button
            style={{ border: 'none' }}
            type="primary"
            onClick={() => setPage(2)}
          >
            Repositório
          </Button>
          <Button
            style={{ border: 'none', marginLeft: 5 }}
            type="primary"
            onClick={() => setPage(1)}
          >
            Arquivo
          </Button>
        </div>
      </div>
    )
  }

  const fileCreateComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        {/* <FaArrowLeft
          size={25}
          color="var(--primary)"
          onClick={() => setPage(0)}
          cursor="pointer"
          style={{ marginBottom: 10, marginTop: 10 }}
        /> */}
        <Input
          style={{ width: 200, backgroundColor: '#fafafa' }}
          bordered={false}
          placeholder="Título"
          onChange={e => setFileTitle(e.target.value)}
        />
        <Select
          placeholder="Repositório"
          style={{ width: 200, marginTop: 10 }}
          onChange={option => setRepository(option.toString())}
        >
          {data.map(item => (
            <Option key={item._id} value={item._id}>
              {item.title}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Visível para"
          style={{ width: 200, marginTop: 10 }}
          onChange={option => setRole(option.toString())}
        >
          {roles?.map(item => (
            <Option key={item._id} value={item._id}>
              {item.name.toUpperCase()}
            </Option>
          ))}
        </Select>
        <FileInput onClick={renderImageInput}>
          <IoIosFolderOpen size={22} style={{ marginRight: 10 }} />
          <Placeholder>{file ? file.name : 'Selecione um arquivo'}</Placeholder>
        </FileInput>
        <input
          onChange={e => {
            if (!e.target.files) return
            setFile(e?.target?.files[0])
          }}
          style={{ display: 'none' }}
          type="file"
          id="img"
          name="img"
          accept="document/*"
          ref={fileInput}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            style={{ border: 'none', marginLeft: 10 }}
            type="primary"
            onClick={() => handleFileCreate()}
          >
            Criar
          </Button>
          <Button
            type="primary"
            style={{ border: 'none', marginLeft: 10 }}
            onClick={() => setPage(0)}
          >
            Voltar
          </Button>
        </div>
      </div>
    )
  }
  const repositoryCreate = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        {/* <FaArrowLeft
          size={25}
          color="var(--primary)"
          onClick={() => setPage(0)}
          cursor="pointer"
          style={{ marginRight: 15 }}
        /> */}
        <Input
          placeholder="Título do repositório"
          bordered={false}
          onChange={e => setRepositoryTitle(e.target.value)}
          style={{ width: 200, backgroundColor: '#fafafa' }}
        />
        <Button
          style={{ border: 'none', marginLeft: 10 }}
          type="primary"
          onClick={() => handleRepositoryCreation()}
        >
          Criar
        </Button>
        <Button
          type="primary"
          style={{ border: 'none', marginLeft: 10 }}
          onClick={() => setPage(0)}
        >
          Voltar
        </Button>
      </div>
    )
  }
  const renderInsideModal = () => {
    switch (page) {
      case 0:
        return selectCreation()
      case 1:
        return fileCreateComponent()
      case 2:
        return repositoryCreate()
    }
  }

  const renderTitle = () => {
    switch (page) {
      case 0:
        return 'Selecionar'
      case 1:
        return 'Criar arquivo'
      case 2:
        return 'Criar repositório'
      default:
        return ''
    }
  }

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties | undefined => ({
    userSelect: 'none',
    padding: 0,
    margin: `0 0 ${0}px 0`,

    background: isDragging ? '' : '',

    ...draggableStyle
  })

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : '',
    padding: 8,
    width: '100%'
  })

  return (
    <Container>
      <Header />
      <SecondHeader
        title="Repositório"
        content={
          checkRole() ? (
            <AddButton
              label="Adicionar"
              onClickEvent={() => setUploadModal(!uploadModal)}
            />
          ) : null
        }
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 24,
          width: '100%'
        }}
      >
        {isLoading ? (
          <div
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
              fontSize: 40
            }}
          >
            <Spin />
          </div>
        ) : (
          <div>
            <DragDropContext
              onDragEnd={result => {
                const { destination, source, draggableId } = result

                if (!destination) return
                handleRepositoryMove(
                  destination?.index,
                  source?.index,
                  data[destination.index]._id
                )
              }}
            >
              <Droppable droppableId="drop">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {data?.map((item, index) => (
                      <Draggable
                        draggableId={item._id}
                        key={item._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <FileAccordion
                              index={index}
                              accordionHook={[
                                selectedAccordion,
                                setSelectedAccordion
                              ]}
                              data={item}
                              key={item._id}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>

      <NewModal
        visible={uploadModal}
        destroyOnClose={true}
        onCancel={() => setUploadModal(false)}
        title={renderTitle()?.toString()}
      >
        {renderInsideModal()}
      </NewModal>
    </Container>
  )
}

export default FileList
