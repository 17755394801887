import { call, put, all, takeLatest } from 'redux-saga/effects'
import { AxiosRequestConfig } from 'axios'
import { AnyAction } from 'redux'

import api from '../../../../services/api'
import { DELETE_PHOTO_REQUEST } from './types'
import { deletePhotoSuccess, deletePhotoFailure } from './actions'
import { getPhotos } from '../getPhotos/actions'

export function* deletePhoto(requestPayload: AnyAction) {
  const { _id, token } = requestPayload

  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      },
      data: {
        _id
      }
    }

    yield call(api.delete, 'gallery/delete', config)

    yield put(deletePhotoSuccess())

    yield put(getPhotos(token))
  } catch {
    yield put(deletePhotoFailure())

    yield put(getPhotos(token))
  }
}

export default all([takeLatest(DELETE_PHOTO_REQUEST, deletePhoto)])
