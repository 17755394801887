import React, { useState, useEffect, createRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Statistic, Form, Button } from 'antd'

import { RootState } from '../../../../store/rootReducers'
import PrimaryButton from '../../../../components/PrimaryButton'
import { checkCode } from '../../../../store/modules/authentication/forgotPassword/checkCode/actions'
import { requestSms } from '../../../../store/modules/authentication/forgotPassword/requestSms/actions'

import {
  Container,
  Error,
  FormContainer,
  FormContent,
  CodeInput,
  SubmitButtonContainer,
  SubmitButtonBlock,
  RequestSmsContainer,
  RequestSmsBlock
} from './styles'

interface Props {
  setStep: (arg: number) => void
}

const CheckCode: React.FC<Props> = ({ setStep }) => {
  const dispatch = useDispatch()
  const { Countdown } = Statistic

  const input0Ref = createRef<Input>()
  const input1Ref = createRef<Input>()
  const input2Ref = createRef<Input>()
  const input3Ref = createRef<Input>()

  const [sendTime, setSendTime] = useState<number | undefined>(undefined)
  const [smsCode, setSmsCode] = useState<string[]>(['', '', '', ''])
  const [phone, setPhone] = useState<string>('')

  const {
    cellphone,
    code,
    mail,
    countdown,
    requestSmsLoading,
    requestSmsFail
  } = useSelector((state: RootState) => state.requestSms)
  const { checkCodeLoading, checkCodeFail, checkCodeError } = useSelector(
    (state: RootState) => state.checkCode
  )

  const isNotAllowedToSubmit: boolean =
    smsCode.includes('') || !!checkCodeLoading

  const handleSetTimeToUndefined = useCallback(() => {
    setSendTime(undefined)
  }, [])

  const previousInput = (key: string, index: number): void => {
    if (key !== 'Backspace') return
    switch (index) {
      case 1:
        input0Ref.current && input0Ref.current.focus()
        break
      case 2:
        input1Ref.current && input1Ref.current.focus()
        break
      case 3:
        input2Ref.current && input2Ref.current.focus()
        break
      default:
        break
    }
  }

  const handleCodeChanged = (value: string, index: number): void => {
    smsCode.splice(index, 1, value)
    setSmsCode([...smsCode])

    if (value) {
      switch (index) {
        case 0:
          input1Ref.current && input1Ref.current.focus()
          break
        case 1:
          input2Ref.current && input2Ref.current.focus()
          break
        case 2:
          input3Ref.current && input3Ref.current.focus()
          break
        default:
          break
      }
    }
  }

  const handleCheckCodeSubmit = useCallback(() => {
    if (!isNotAllowedToSubmit && code) {
      const formattedSmsCode = smsCode.join('')

      dispatch(checkCode(code, formattedSmsCode))
    }
  }, [smsCode])

  const handleRequestSmsSubmit = useCallback(() => {
    if (mail) {
      dispatch(requestSms(mail))
    }
  }, [])

  const handleNavigateToContactStep = useCallback(() => {
    setStep(1)
  }, [])

  useEffect(() => {
    if (cellphone) setPhone(cellphone)

    input0Ref.current && input0Ref.current.focus()
  }, [])

  useEffect(() => {
    if (checkCodeLoading === false && checkCodeFail === false) {
      setStep(3)
    }
  }, [checkCodeLoading, checkCodeFail])

  useEffect(() => {
    if (!requestSmsLoading && !requestSmsFail && countdown) {
      setSendTime(Date.now() + countdown)
    }
  }, [requestSmsLoading, requestSmsFail, countdown])

  return (
    <Container>
      <span>Enviamos um codigo de SMS para o número</span>

      <strong>{phone}</strong>

      <Button
        type="link"
        onClick={handleNavigateToContactStep}
        style={{ marginTop: 10 }}
      >
        Número estranho?
      </Button>
      <FormContainer>
        <Form name="checkcode">
          <FormContent>
            <Form.Item
              name="code0"
              rules={[{ required: true, message: 'Vazio' }]}
            >
              <CodeInput
                maxLength={1}
                ref={input0Ref}
                onChange={e => handleCodeChanged(e.target.value, 0)}
                onKeyUp={e => previousInput(e.key, 0)}
              />
            </Form.Item>

            <Form.Item
              name="code1"
              rules={[{ required: true, message: 'Vazio' }]}
            >
              <CodeInput
                maxLength={1}
                ref={input1Ref}
                onChange={e => handleCodeChanged(e.target.value, 1)}
                onKeyUp={e => previousInput(e.key, 1)}
              />
            </Form.Item>

            <Form.Item
              name="code2"
              rules={[{ required: true, message: 'Vazio' }]}
            >
              <CodeInput
                maxLength={1}
                ref={input2Ref}
                onChange={e => handleCodeChanged(e.target.value, 2)}
                onKeyUp={e => previousInput(e.key, 2)}
              />
            </Form.Item>

            <Form.Item
              name="code3"
              rules={[{ required: true, message: 'Vazio' }]}
            >
              <CodeInput
                maxLength={1}
                ref={input3Ref}
                onChange={e => handleCodeChanged(e.target.value, 3)}
                onKeyUp={e => previousInput(e.key, 3)}
              />
            </Form.Item>
          </FormContent>
          <SubmitButtonContainer>
            {checkCodeFail && !!checkCodeError && (
              <Error>
                <span>{checkCodeError}</span>
              </Error>
            )}

            <SubmitButtonBlock>
              <PrimaryButton
                label="Validar SMS"
                onClick={handleCheckCodeSubmit}
                disabled={isNotAllowedToSubmit}
              />
            </SubmitButtonBlock>
          </SubmitButtonContainer>
        </Form>
        <RequestSmsContainer>
          <span>Não recebeu o SMS?</span>
          <RequestSmsBlock>
            <Countdown
              value={sendTime}
              format="mm:ss"
              onFinish={handleSetTimeToUndefined}
            />
            <PrimaryButton
              label="Reenviar"
              onClick={handleRequestSmsSubmit}
              disabled={sendTime !== undefined || !mail}
              style={{ width: 180 }}
            />
          </RequestSmsBlock>
        </RequestSmsContainer>
      </FormContainer>
    </Container>
  )
}

export default CheckCode
