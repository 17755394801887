import React from 'react'
import { FaLeaf } from 'react-icons/fa'
import { LoadingContainer } from './styles'

const LoadingScreen: React.FC = () => {
  return (
    <LoadingContainer>
      <FaLeaf color="var(--green)" size={50} />
    </LoadingContainer>
  )
}

export default LoadingScreen
