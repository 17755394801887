export const POST_PHOTO_REQUEST = '@gallery/POST_PHOTO_REQUEST'
export const POST_PHOTO_SUCCESS = '@gallery/POST_PHOTO_SUCCESS'
export const POST_PHOTO_FAILURE = '@gallery/POST_PHOTO_FAILURE'

export const POST_PHOTO_ERASE = '@gallery/POST_PHOTO_ERASE'

export type PostPhotoState = {
  postPhotoLoading: boolean | null
  postPhotoFail: boolean | null
}

type PostPhotoAction = {
  type: typeof POST_PHOTO_REQUEST
  photo: FormData
  token: string
}

type PostPhotoSuccessAction = {
  type: typeof POST_PHOTO_SUCCESS
}

type PostPhotoFailureAction = {
  type: typeof POST_PHOTO_FAILURE
}

type PostPhotoEraseAction = {
  type: typeof POST_PHOTO_ERASE
}

export type PostPhotoActionTypes =
  | PostPhotoAction
  | PostPhotoSuccessAction
  | PostPhotoFailureAction
  | PostPhotoEraseAction
