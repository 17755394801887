import styled from 'styled-components'

export const MenuTitleText = styled.span`
  color: #888888;
  font-size: 16px;
`

export const PopoverContentBlock = styled.div`
  display: flex;
  flex-direction: column;
`
