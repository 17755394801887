import React from 'react'

import unselectedYear from '../../assets/unselectedYear.svg'
import selectedYear from '../../assets/selectedYear.svg'
import { EventType } from '../../store/modules/events/getEvents/types'
import EventCard from '../EventCard'

import {
  Container,
  EventsContainer,
  YearContainer,
  YearBlockImage,
  YearText
} from './styles'

interface Props {
  year: string
  events: EventType[]
}

const TimelineCard: React.FC<Props> = ({ year, events }) => {
  const currentYear = new Date().getFullYear().toString()

  return (
    <Container>
      <EventsContainer>
        {events?.map(event => (
          <EventCard
            key={event._id}
            _id={event._id}
            year={year}
            description={event.description}
          />
        ))}
      </EventsContainer>
      <YearContainer>
        <YearBlockImage
          src={year === currentYear ? selectedYear : unselectedYear}
        />
        <YearText>{year}</YearText>
      </YearContainer>
    </Container>
  )
}

export default TimelineCard
