import styled from 'styled-components'

interface ButtonProps {
  disabled: boolean | undefined
}

export const ButtonBlock = styled.button<ButtonProps>`
  background: none;
  outline: none;
  text-align: center;
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => (props.disabled ? '#cccccc' : '#3b831e')};
  padding: 10px 0;
  width: 100%;
  transition: 0.3s;
  border-radius: 30px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props => (props.disabled ? '#cccccc' : '#306b19')};
  }
`

export const ButtonLabelText = styled.span`
  font-size: 18px;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
`
