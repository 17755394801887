import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../../services/api'
import { getUserInfoFailure, getUserInfoSuccess } from './actions'
import { GET_USER_INFO_REQUEST } from './types'

export function* getUserInfo(requestPayload: AnyAction) {
  try {
    const { token } = requestPayload

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token
      }
    }

    const response: AxiosResponse = yield call(api.get, 'user/me', config)

    const { data } = response

    yield put(getUserInfoSuccess(data))
  } catch {
    yield put(getUserInfoFailure())
  }
}

export default all([takeLatest(GET_USER_INFO_REQUEST, getUserInfo)])
