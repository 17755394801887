import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const PageContent = styled.div`
  display: flex;
  height: 70%;
  align-items: center;
  justify-content: center;
`

export const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`

export const SliderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  color: #afafaf;
  font-size: 18px;

  border: none;

  &:focus {
    border: none;
    outline: none;
  }
`

export const TimelineBlock = styled.div`
  width: 80%;
  overflow: hidden;
  margin: 0 20px;
`

export const TimelineScrollView = styled.div`
  display: inline-flex;
  align-items: flex-end;
`

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 320px;
`

export const DescInput = styled.textarea`
  border: none;
  resize: none;
  background-color: #fafafa;
  padding: 10px;
  width: 100%;
  height: 100%;
  font-family: var(--font);
  font-size: 20px;

  &:focus {
    outline: none;
  }
`

export const YearInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 160px;
  height: 100%;
`

export const CalendarIcon = styled.img`
  position: absolute;
  top: 87px;
  right: 147px;
`

export const YearInput = styled.input`
  border: none;
  background-color: #fafafa;
  font-family: var(--font);
  font-size: 20px;
  padding: 10px 10px 10px 40px;
  margin-bottom: 253px;

  &:focus {
    outline: none;
  }
`
