import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import PrimaryButton from '../../components/PrimaryButton'
import { RootState } from '../../store/rootReducers'

import {
  PageContainer,
  BgContainer,
  FirstText,
  SecondText,
  TextBlock,
  ThirdText
} from './styles'

const LandingPage: React.FC = () => {
  const { push } = useHistory()

  const { signed } = useSelector((state: RootState) => state.signIn)

  const handleNavigateToSignIn = (): void => {
    push('/authentication/signin')
  }

  useEffect(() => {
    if (signed) push('/projectpage')
  }, [signed])

  return (
    <PageContainer>
      <Header />
      <BgContainer>
        <FirstText>Planejamento Estratégico 2021</FirstText>
        <SecondText>Equilíbrio Fertilizantes</SecondText>
        <TextBlock>
          <ThirdText>Nutrindo Confiança</ThirdText>
          <PrimaryButton
            onClick={handleNavigateToSignIn}
            style={{ marginTop: 40 }}
            label="Entrar"
          />
        </TextBlock>
      </BgContainer>
      <Footer />
    </PageContainer>
  )
}

export default LandingPage
