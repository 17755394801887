/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import { Button, DatePicker, Input, notification, Popover, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { AiOutlineClose } from 'react-icons/ai'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { keyInterface, mutate } from 'swr'
import { useFetch } from '../../hooks/useFetch'
import { Tags } from '../../pages/ProjectPage'
import {
  ProjectModalContainer,
  ProjectModalDescriptionContainer,
  ProjectModalRow,
  ProjectModalSelectionColumn,
  ProjectModalTextArea,
  ProjectModalTextHeader,
  SelectedTagsContainer,
  TagDivider,
  TagListContainer,
  TagListInnerContainer
} from '../../pages/ProjectPage/styles'
import api from '../../services/api'
import { RootState } from '../../store/rootReducers'
import AddButton from '../AddButton'
import NewModal from '../NewModal'
import Tag from '../Tag'
import {
  ActionsBoardContainer,
  CardBody,
  CardContainer,
  CardContent,
  CardPrazo,
  ColumnHeader,
  ColumnTitle,
  InnerContainer,

  LoadingIcon, TagContainer
} from './styles'

interface CardTag {
  id: number
  title: string
  color: string
}

export interface Card {
  createdAt: string
  owner: string
  tags: Tags[]
  title: string
  updatedAt: string
  description: string
  endDate: string
  _id: string
  board: string
}

export interface Board {
  cards: Card[]
}

export interface fResponse {
  createdAt: string
  createdBy: string
  header: string
  title: string
  updatedAt: string
  _id: string
  cards: Card[]
}

interface EditCardPayload {
  card: string
  board: string
  title: string
  owner: string
  endDate: string
  description: string
  tags: Tags[]
}
interface EditingBoard {
  name?: string
  _id?: string | undefined
}

interface EditingCard {
  card?: string
  board?: string
  title?: string
  owner?: string
  endDate?: string
  tags?: CardTag
}
interface OwnerOption {
  _id: string
  name: string
}
type Props = {
  fetchedData: fResponse[] | undefined
  mutateStr: keyInterface
}
const ProjectBoard: React.FC<Props> = ({ fetchedData, mutateStr }) => {
  const [columns, setColumns] = useState<fResponse[] | null>()
  const [editingBoard, setEditingBoard] = useState<EditingBoard>()
  const [editingCardModel, setEditingCardModel] = useState<EditingCard>()
  const [visible, setVisible] = useState<boolean>(false)

  const [editModal, setEditModal] = useState<boolean>(false)
  const [editCard, setEditCard] = useState<boolean>(false)

  const [description, setDescription] = useState<string>('')
  const [limitDate, setLimitDate] = useState<string | any>('')
  const [owner, setOwner] = useState<string>('')
  const [board, setBoard] = useState<string>('')
  const [title, setTitle] = useState<string>('')

  const [card, setCard] = useState<string>('')
  const [selectedTags, setSelectedTags] = useState<Tags[]>([])

  const dateFormat = 'DD/MM/YYYY'

  const { token } = useSelector((state: RootState) => state.signIn)
  const { Option } = Select

  const { data: ownerOptions } = useFetch<OwnerOption[]>(
    'project/users',
    token,
    {}
  )

  const { data: tags } = useFetch<Tags[]>('project/tag', token, {})

  function disabledDate(current: Moment) {
    return current && current < moment().endOf('day')
  }

  const onDragEnd = (result: DropResult, columns: Board[] | any) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns.filter(
        (item: any) => item._id === source.droppableId
      )[0]
      const destColumn = columns.filter(
        (item: any) => item._id === destination.droppableId
      )[0]

      const sourceItems = [...sourceColumn.cards]
      const destItems = [...destColumn.cards]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)

      if (
        [
          { ...sourceColumn, cards: sourceItems },
          { ...destColumn, cards: destItems }
        ].includes(undefined)
      ) {
        return
      }

      sourceColumn.cards = sourceItems
      destColumn.cards = destItems

      api
        .patch(
          'project/card/move',
          {
            _id: sourceColumn._id,
            cards: {
              _id: result.draggableId
            },
            for: destColumn._id
          },
          { headers: { authorization: token } }
        )
        .then(res => {
          if (res.status === 200) {
            notification.success({ message: 'Card movido com sucesso!' })
          } else {
            notification.error({
              message: 'Algo deu errado ao tentar mover card'
            })
          }
        })
    } else {
      const column = columns.filter(
        (item: fResponse) => item._id === source.droppableId
      )[0]
      const copiedItems = [...column.cards]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)

      column.cards = copiedItems
    }
  }

  const handleTagSelection = (item: Tags) => {
    if (selectedTags?.includes(item)) {
      const newArray = selectedTags.filter(tag => tag !== item)
      setSelectedTags(newArray)
    } else {
      setSelectedTags([...selectedTags, item])
    }
  }

  const TagsComponent = () => (
    <TagListContainer>
      <TagListInnerContainer style={{ textAlign: 'center' }}>
        <AiOutlineClose
          size={30}
          onClick={() => setVisible(!visible)}
          cursor="pointer"
        />
        {tags?.map(item => (
          <TagDivider key={item._id} onClick={() => handleTagSelection(item)}>
            <Tag
              key={item._id}
              card={false}
              content={item.title}
              selected={!!selectedTags.find(tag => tag._id === item._id)}
              backgroundColor={item.color}
              width={100}
              height={30}
            />
          </TagDivider>
        ))}
      </TagListInnerContainer>
    </TagListContainer>
  )
  useEffect(() => {
    setColumns(fetchedData)
  }, [fetchedData])

  const handleBoardDelete = (id: string) => {
    api
      .delete('project/board/delete', {
        data: { board: id },
        headers: { authorization: token }
      })
      .then(res => {
        if (res.status === 200) {
          mutate(mutateStr)
          mutate('audit')
          notification.success({ message: 'Quadro deletado com sucesso!' })
        } else {
          notification.error({ message: 'Erro ao tentar deletar quadro!' })
        }
      })
  }

  const handleBoardEdit = () => {
    api
      .put(
        '/project/board/edit',
        {
          board: editingBoard?._id,
          title: editingBoard?.name
        },
        { headers: { authorization: token } }
      )
      .then(res => {
        if (res.status === 200) {
          mutate('project/board')
          mutate('audit')

          notification.success({
            message: 'Nome do quadro editado com sucesso!'
          })
          setEditCard(false)
        } else {
          notification.error({
            message: 'Erro ao tentar editar nome do quadro!'
          })
        }
      })
  }

  const handleCardDelete = (id: string) => {
    api
      .delete('project/card/delete', {
        headers: {
          authorization: token
        },
        data: {
          card: id
        }
      })
      .then(res => {
        if (res.status === 200) {
          mutate(mutateStr)
          mutate('audit')
          mutate('project/card/calendar')
          notification.success({ message: 'Card Deletado com Sucesso' })
        } else {
          notification.error({ message: 'Não foi possível deletar o card!' })
        }
      })
  }
  const handleCardEdit = () => {
    const editPayload: EditCardPayload = {
      title,
      description: description,
      board,
      card,
      endDate: limitDate === 'Data inválida' ? '' : limitDate,
      owner,
      tags: selectedTags
    }
    api
      .put('project/card/edit', editPayload, {
        headers: {
          authorization: token
        }
      })
      .then(res => {
        if (res.status === 200) {
          mutate(mutateStr).then(() => {
            notification.success({ message: 'Card editado com sucesso!' })
          })
          mutate('project/card/calendar')
          mutate('project')
          mutate('audit')
          setEditCard(!editCard)
        } else {
          notification.error({
            message: 'Houve um erro ao tentar editar o card!'
          })
        }
      })
  }
  if (!fetchedData && !columns) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <LoadingIcon size={50} color="var(--green)">
          Carregando...
        </LoadingIcon>
      </div>
    )
  } else {
    return (
      <>
        <div
          style={{
            display: 'flex',
            maxHeight: 600,
            maxWidth: 1400,
            overflowX: 'auto'
          }}
        >
          <DragDropContext
            onDragEnd={result => {
              onDragEnd(result, columns)
            }}
          >
            {columns?.map(item => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxHeight: 600,
                    marginTop: 20
                  }}
                  key={item._id}
                >
                  <ColumnHeader>
                    <div
                      style={{
                        width: '95%',
                        minWidth: '220px',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 15
                      }}
                    >
                      <ColumnTitle>{item.title}</ColumnTitle>
                    </div>
                    <ActionsBoardContainer>
                      <BiPencil
                        size={20}
                        cursor="pointer"
                        onClick={() => {
                          setEditingBoard({ _id: item._id, name: item.title })
                          setEditModal(!editModal)
                        }}
                      />
                      <BiTrash
                        size={20}
                        cursor="pointer"
                        style={{ marginLeft: 3 }}
                        onClick={() => handleBoardDelete(item._id)}
                      />
                    </ActionsBoardContainer>
                  </ColumnHeader>

                  <div style={{ margin: 8 }}>
                    <Droppable droppableId={item._id} key={item._id}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided?.droppableProps}
                            ref={provided?.innerRef}
                            style={{
                              background: snapshot?.isDraggingOver
                                ? 'lightblue'
                                : 'transparent',
                              padding: 5,
                              display: 'flex',
                              width: 430,
                              maxHeight: 800,
                              flexDirection: 'column'
                            }}
                          >
                            {item.cards.map((card: Card, index: number) => {
                              return (
                                <Draggable
                                  key={card._id}
                                  draggableId={card._id}
                                  index={index}
                                >
                                  {provided => {
                                    return (
                                      <CardContainer
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <InnerContainer>
                                          <div
                                            style={{
                                              marginLeft: '88%',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              width: '100%'
                                            }}
                                          >
                                            <BiPencil
                                              size={22}
                                              onClick={() => {
                                                setTitle(card.title)
                                                setCard(card._id)
                                                setBoard(card.board)
                                                setOwner(card.owner)
                                                setSelectedTags(card.tags)
                                                setLimitDate(
                                                  card?.endDate !== null
                                                    ? card?.endDate?.replace(
                                                        'T00:00:00.000Z',
                                                        ''
                                                      )
                                                    : moment()
                                                )
                                                setDescription(card.description)
                                                setEditCard(!editCard)
                                              }}
                                              style={{ marginRight: 3 }}
                                            />
                                            <BiTrash
                                              size={22}
                                              onClick={() =>
                                                handleCardDelete(card._id)
                                              }
                                            />
                                          </div>
                                          <TagContainer>
                                            {card?.tags?.map(tag => (
                                              <Tag
                                                card={true}
                                                backgroundColor={tag.color}
                                                content={tag.title}
                                                width="120px"
                                                height="20px"
                                                key={tag._id}
                                              />
                                            ))}
                                          </TagContainer>

                                          <CardBody>
                                            <h3>{card.title}</h3>
                                            <CardContent>
                                              {card.description}
                                            </CardContent>
                                          </CardBody>
                                          <CardPrazo>
                                            {card?.endDate
                                              ? 'Prazo: ' +
                                                moment(
                                                  card?.endDate?.replace(
                                                    'T00:00:00.000Z',
                                                    ''
                                                  )
                                                ).format('DD/MM/YYYY')
                                              : '-'}
                                          </CardPrazo>
                                        </InnerContainer>
                                      </CardContainer>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        )
                      }}
                    </Droppable>
                  </div>
                </div>
              )
            })}
          </DragDropContext>
        </div>
        <NewModal
          key="Editar quadro"
          visible={editModal}
          onCancel={() => setEditModal(!editModal)}
          title={`Editar quadro ${editingBoard?.name}`}
        >
          <div style={{ flexDirection: 'row', display: 'flex ' }}>
            <Input
              onChange={e =>
                setEditingBoard({
                  _id: editingBoard?._id,
                  name: e.target.value
                })
              }
              bordered={false}
              style={{ backgroundColor: '#fafafa' }}
              value={editingBoard?.name}
              placeholder="Novo quadro"
            />
            <Button
              style={{
                backgroundColor: '#57bb00',
                border: 'none',
                outline: 0,
                color: 'white',
                marginLeft: 5
              }}
              onClick={() => handleBoardEdit()}
            >
              Editar
            </Button>
          </div>
        </NewModal>
        <NewModal
          key="editar card"
          title="Editar card"
          visible={editCard}
          onCancel={() => {
            setVisible(false)
            setTimeout(() => {
              setEditCard(!editCard)
            }, 1)
          }}
        >
          <ProjectModalContainer style={{ width: 350, padding: 0 }}>
            <ProjectModalRow>
              <ProjectModalDescriptionContainer>
                <ProjectModalTextHeader>
                  <Input
                    placeholder="Título"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    bordered={false}
                    style={{
                      color: 'white',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                      outline: 0,
                      backgroundColor: 'transparent'
                    }}
                  />
                </ProjectModalTextHeader>
                <ProjectModalTextArea
                  style={{ height: 280 }}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </ProjectModalDescriptionContainer>

              <ProjectModalSelectionColumn>
                <Select
                  size="large"
                  value={owner}
                  style={{
                    width: 200,
                    backgroundColor: '#fafafa'
                  }}
                  onChange={e => setOwner(e.toString())}
                >
                  {ownerOptions?.map(item => (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>

                {/* <Select
                  bordered={false}
                  value={board}
                  style={{ width: 200, marginTop: 8 }}
                  size="large"
                  onChange={e => setBoard(e.toString())}
                >
                  {fetchedData?.map(item => (
                    <Option key={item._id} value={item._id}>
                      {item.title}
                    </Option>
                  ))}
                </Select> */}

                <Popover
                  placement="bottom"
                  trigger="click"
                  visible={visible}
                  content={TagsComponent}
                  key="Adicionar card"
                >
                  <div
                    onClick={() => setVisible(!visible)}
                    style={{
                      width: 200,
                      border: 'none',
                      backgroundColor: '#fafafa',
                      height: 40,
                      padding: '11px 11px',
                      display: 'flex',
                      alignItems: 'center ',
                      borderRadius: 2,
                      cursor: 'pointer',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span style={{ color: '#c3c3c3', fontSize: 16 }}>Tags</span>
                    <span
                      style={{
                        color: '#c3c3c3',
                        fontSize: 12,
                        marginTop: 10
                      }}
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                      </svg>
                    </span>
                  </div>
                </Popover>

                <DatePicker
                  onChange={e => setLimitDate(moment(e).format('YYYY-MM-DD'))}
                  style={{ width: 200, marginTop: 8 }}
                  locale={locale}
                  value={moment(limitDate)}
                  disabledDate={disabledDate}
                  format={dateFormat}
                  bordered={false}
                />
                {tags ? tags?.length > 0 ? <h2>Tags</h2> : null : null}
                <SelectedTagsContainer>
                  {selectedTags?.map((item, index) => (
                    <div
                      key={item._id}
                      onClick={() => handleTagSelection(item)}
                    >
                      <Tag
                        card={true}
                        content={item.title}
                        backgroundColor={item.color}
                        width={30}
                        height={30}
                        key={index}
                      />
                    </div>
                  ))}
                </SelectedTagsContainer>
                <AddButton
                  label="Editar"
                  onClickEvent={() => handleCardEdit()}
                  height="40px"
                />
              </ProjectModalSelectionColumn>
            </ProjectModalRow>
          </ProjectModalContainer>
        </NewModal>
      </>
    )
  }
}

export default ProjectBoard
