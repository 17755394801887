/* eslint-disable generator-star-spacing */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all } from 'redux-saga/effects'

import getEvents from './modules/events/getEvents/saga'
import signIn from './modules/authentication/signIn/saga'
import postEvent from './modules/events/postEvent/saga'
import deleteEvent from './modules/events/deleteEvent/saga'
import updateEvent from './modules/events/updateEvent/saga'
import getUserInfo from './modules/user/getUserInfo/saga'
import updateUserInfo from './modules/user/updateUserInfo/saga'
import changePwd from './modules/user/changePwd/saga'
import updateUserAvatar from './modules/user/updateUserAvatar/saga'
import getPhotos from './modules/gallery/getPhotos/saga'
import postPhoto from './modules/gallery/postPhoto/saga'
import deletePhoto from './modules/gallery/deletePhoto/saga'
import requestSms from './modules/authentication/forgotPassword/requestSms/saga'
import checkCode from './modules/authentication/forgotPassword/checkCode/saga'
import resetPassword from './modules/authentication/forgotPassword/resetPassword/saga'

export default function* rootSagas() {
  return yield all([
    getEvents,
    signIn,
    postEvent,
    deleteEvent,
    updateEvent,
    getUserInfo,
    updateUserInfo,
    changePwd,
    updateUserAvatar,
    getPhotos,
    postPhoto,
    deletePhoto,
    requestSms,
    checkCode,
    resetPassword
  ])
}
