import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoMdLock, IoMdPerson } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import PrimaryButton from '../../components/PrimaryButton'
import {
  signIn,
  signOut
} from '../../store/modules/authentication/signIn/actions'
import { RootState } from '../../store/rootReducers'
import isMailValid from '../../utils/isMailValid'

import {
  BackButton,
  BgContainer,
  ErrorBlock,
  ErrorText,
  FormContainer,
  LinkText,
  PageContainer,
  SignInEmailInput,
  SignInPwdInput,
  TitleText
} from './styles'

const SignInPage: React.FC = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { signInLoading, signInFail, signInError } = useSelector(
    (state: RootState) => state.signIn
  )

  const handleSignInSubmit = (): void => {
    if (!signInLoading) {
      dispatch(signIn({ email, password }))
    }
  }

  const isAllowToSubmit: boolean =
    email === '' ||
    password === '' ||
    password.length < 8 ||
    !isMailValid(email)

  const renderSignInError = (): React.ReactNode => {
    if (signInFail && signInError) {
      return (
        <ErrorBlock>
          <ErrorText>{signInError}</ErrorText>
        </ErrorBlock>
      )
    }

    return <></>
  }

  useEffect(() => {
    if (signInFail) dispatch(signOut())
  }, [])

  if (signInLoading) {
    return <LoadingScreen />
  }

  return (
    <PageContainer>
      <Header />
      <BgContainer>
        <TitleText>Planejamento Estratégico 2021</TitleText>
        <FormContainer>
          <Form
            name="signIn"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Insira o seu e-mail!' },
                { type: 'email', message: 'E-mail inválido' }
              ]}
            >
              <SignInEmailInput
                placeholder="Usuário"
                prefix={<IoMdPerson color="#CCCCCC" />}
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Insira a sua senha!' },
                { min: 8, message: 'A senha deve ter 8 ou mais caracteres' }
              ]}
            >
              <SignInPwdInput
                placeholder="Senha"
                prefix={<IoMdLock color="#CCCCCC" />}
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
              />
            </Form.Item>
            <LinkText to="/authentication/forgotPassword">
              Esqueceu a senha?
            </LinkText>
            {renderSignInError()}
            <Form.Item>
              <PrimaryButton
                label="Entrar"
                style={{ marginTop: 30 }}
                onClick={handleSignInSubmit}
                disabled={isAllowToSubmit}
              />
            </Form.Item>
            <BackButton to="/">Voltar</BackButton>
          </Form>
        </FormContainer>
      </BgContainer>
      <Footer />
    </PageContainer>
  )
}

export default SignInPage
