import produce from 'immer'

import {
  CheckCodeActionTypes,
  CheckCodeState,
  CHECK_CODE_ERASE,
  CHECK_CODE_FAILURE,
  CHECK_CODE_REQUEST,
  CHECK_CODE_SUCCESS
} from './types'

const INITIAL_STATE: CheckCodeState = {
  checkCodeLoading: null,
  checkCodeError: null,
  checkCodeFail: null
}

export default function checkCode(
  state = INITIAL_STATE,
  action: CheckCodeActionTypes
): CheckCodeState {
  return produce(state, draft => {
    switch (action.type) {
      case CHECK_CODE_REQUEST:
        draft.checkCodeLoading = true
        draft.checkCodeFail = INITIAL_STATE.checkCodeFail
        draft.checkCodeError = INITIAL_STATE.checkCodeError
        break
      case CHECK_CODE_SUCCESS:
        draft.checkCodeLoading = false
        draft.checkCodeFail = false
        break
      case CHECK_CODE_FAILURE:
        draft.checkCodeLoading = false
        draft.checkCodeFail = true
        draft.checkCodeError = action.response.message
        break
      case CHECK_CODE_ERASE:
        draft.checkCodeLoading = INITIAL_STATE.checkCodeLoading
        draft.checkCodeFail = INITIAL_STATE.checkCodeFail
        draft.checkCodeError = INITIAL_STATE.checkCodeError
        break
      default:
        return state
    }
  })
}
